import React from "react";
import { motion } from "framer-motion";
import VondoomOs from "../../../Assets/VonDoomOS.png";
import { ReactComponent as Twitter } from "../../../Assets/Twitter.svg";
import { ReactComponent as Instagram } from "../../../Assets/Instagram.svg";
import WebxMarquee from "../../Marquees/WebxMarquee";
import VonAdvisorMarquee from "../../Marquees/VonAdvisorMarquee";
import { CalendlyBtn } from "../../CalendlyBtn/CalendlyBtn";

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const heroAnimation = {
  offScreen: {
    opacity: 0,
    y: 225,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const VondoomMiddle = () => {
  return (
    <div className="text-white font-jersey">
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.1 }}
        className="justify-center items-center   "
      >
        <div className="text-center pb-20 tab:pb-14 ">
          <div className="font-jersey text-7xl ml:text-8xl tab:text-9xl mb-10">
            <p>VONDOOM</p>
          </div>
          <div className="max-w-5xl mx-auto font-jersey px-6 tab:px-0 text-2xl mm:text-4xl bug:text-5xl  pb-20">
            Active in cryptocurrency since 2013, and nfts since late 2020
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.1 }}
        className="justify-center items-center   "
      >
        <div className="text-center pt-20 tab:pt-14 ">
          <div className="font-jersey text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl mb-10 px-4">
            <p>One of the largest NFT collectors</p>
          </div>
          <div className="max-w-5xl mx-auto font-jersey text-base bug:text-2xl pepe:text-3xl px-6 pb-10 tab:pb-20">
            VonDoom's personal collection of NFTs consists of over 5,000 items,
            including BAYC, MAYC, Moonbirds, Cool Cats, GCG, BYOPills, DeGods,
            Liberty Square, y00ts, CloneX, Renga, and Azuki, among others.
          </div>
        </div>
      </motion.div>
      <img
        src={VondoomOs}
        alt="partner"
        className="w-[90%] tab:w-[70%] mx-auto tab:rounded-3xl mb-44"
      />

      <motion.div
        variants={heroAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.1 }}
        className="flex justify-center items-center"
      >
        <div>
          <div className="text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl flex justify-center items-center gap-4 ">
            THE DROP
            <a
              href="https://twitter.com/ThedropNFT"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter className="w-10 h-10 tab:h-14  tab:w-14" />
            </a>
          </div>

          <div className=" text-base bug:text-2xl text-center pepe:text-3xl pt-10 max-w-5xl">
            Co-founder The Drop, an NFT newsletter a media company with 35k
            monthly Subscribers{" "}
          </div>
        </div>
      </motion.div>

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn
          buttonType="actionCall"
          label="consult our experts for free"
        />
      </div>

      <motion.div
        variants={heroAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.1 }}
        className=""
      >
        <div>
          <div className="text-5xl bug:text-6xl pepe:text-7xl flex justify-center items-center gap-4 ">
            W3bx
            <a
              href="https://twitter.com/web3expo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter className="w-10 h-10 tab:h-14  tab:w-14" />
            </a>
            <a
              href="https://www.instagram.com/web3expo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram className="w-10 h-10 tab:h-14  tab:w-14" />
            </a>
          </div>

          <div className="pb-24 text-base bug:text-2xl pepe:text-3xl pt-10 max-w-5xl flex justify-center items-center mx-auto">
            <div>
              <div className="text-3xl tab:text-5xl text-center pb-4">
                Co-founder
              </div>
              <p className="text-center px-4">
                Las Vegas 2022 had 7k people at the Wynn / currently planning a
                much larger event for 2024 in a partnership with the City of Las
                Vegas
              </p>

              <div className=" flex flex-col justify-center items-center space-y-4 pt-10">
                <p className="underline">Among the speakers were</p>
                <p>Ryan Carson co-founder of Moonbirds</p>
                <p>Mr Wonderful, Kevin O’leary</p>
                <p>Yat Siu, co-founder of Animoca Brands</p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <WebxMarquee />

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn buttonType="actionCall" label="book free consultation" />
      </div>

      <motion.div
        variants={heroAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.1 }}
      >
        <div>
          <div className="text-5xl bug:text-6xl pepe:text-7xl flex justify-center items-center gap-4 ">
            loveless city
            <a
              href="https://twitter.com/Loveless_City"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter className="w-10 h-10 tab:h-14  tab:w-14" />
            </a>
          </div>

          <div className="pb-24 text-base bug:text-2xl pepe:text-3xl pt-10 max-w-5xl flex justify-center items-center mx-auto">
            <div>
              <div className="text-3xl tab:text-5xl text-center tab:pt-10 pb-4">
                Co-founder
              </div>
              <p className="text-center px-4">
                3 sold out collections and over 2k in ETH traded on the
                secondary
              </p>

              <div className=" flex flex-col justify-center items-center space-y-4 pt-16 px-6 ">
                <p className="underline pb-2 tab:pb-6">Advisory Boards in</p>
                <p className="text-center">
                  Blokpax Ecosystem{" "}
                  <span className="font-sans font-bold">-</span> Advisor{" "}
                  <span className="font-sans font-bold">(</span>Lost Miners
                  <span className="font-sans font-bold">,</span> Bantams
                  <span className="font-sans font-bold">,</span>{" "}
                  <span className="font-mono font-bold">$</span>BPX
                  <span className="font-sans font-bold">)</span>
                </p>
                <p className="text-center">
                  Creepz <span className="font-sans font-bold">/</span>
                  Overlord <span className="font-sans font-bold">-</span>{" "}
                  Advisor 3 sold out collections in 2 months
                </p>
                <p>
                  Applied Primate <span className="font-sans font-bold">-</span>{" "}
                  Advisor
                </p>
                <p>
                  OKX / Party Icons{" "}
                  <span className="font-sans font-bold">-</span> Advisor
                </p>
                <p>
                  Liberty Square <span className="font-sans font-bold">-</span>{" "}
                  Advisor
                </p>
                <p>
                  Kreatorhood <span className="font-sans font-bold">-</span>{" "}
                  Advisor
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <VonAdvisorMarquee />
    </div>
  );
};

export default VondoomMiddle;
