import React from "react";
import turkish from "../../../Assets/TurkishAirlines.jpeg";
import champions from "../../../Assets/Champions.jpeg";
import { ReactComponent as Twitter } from "../../../Assets/Twitter.svg";
import { ReactComponent as Instagram } from "../../../Assets/Instagram.svg";
import { ReactComponent as Tiktok } from "../../../Assets/Tiktok.svg";
import { motion } from "framer-motion";
import { CalendlyBtn } from "../../CalendlyBtn/CalendlyBtn";

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const heroAnimation = {
  offScreen: {
    opacity: 0,
    y: 225,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const VaskangeMiddle = () => {
  return (
    <div className="text-white font-jersey">
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
        className="justify-center items-center   "
      >
        <div className="text-center pb-20 tab:pb-32 ">
          <div className="font-jersey text-7xl ml:text-8xl tab:text-9xl mb-10">
            <p>VASKANGE</p>
            <div className="flex gap-4 tab:gap-8   justify-center items-center pt-4">
              <a
                href="https://twitter.com/Vaskange"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter className="h-12 w-12 tab:h-16 tab:w-16" />
              </a>
              <a
                href="https://www.instagram.com/vaskange/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram className="h-12 w-12 tab:h-16 tab:w-16" />
              </a>
              <a
                href="https://www.tiktok.com/@vaskange"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Tiktok className="h-12 w-12 tab:h-16 tab:w-16" />
              </a>
            </div>
          </div>
          <div className="max-w-5xl mx-auto font-jersey  text-2xl tab:text-4xl  pb-20 px-6">
            A french artist based in Lyon, France. Vaskange is one of the
            biggest artist renowned for his incredibly unique Infinite Zoom,
            boasting 100 000s of followers on his social accounts.
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={heroAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
        className="tab:flex justify-center items-center tab:px-4  tab:space-x-20 bug:space-x-36 pepe:space-x-44"
      >
        <div>
          <video
            loop
            muted
            autoPlay
            playsInline
            src={
              "https://firebasestorage.googleapis.com/v0/b/webuidl-db.appspot.com/o/vaskange.mp4?alt=media&token=de830ce8-3368-490e-a73b-de7610762b6f"
            }
            className="h-64 w-64 tab:h-80 tab:w-80 bug:h-[28rem] bug:w-[28rem] rounded-3xl tab:block mx-auto"
            type="video/mp4"
          />
        </div>
        <div className="text-center tab:text-start pt-20 tab:pt-0 ">
          <div className="font-jersey text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl mb-10">
            <p>CHAMPIONS</p>
            <div className=" flex tab:block justify-center items-center space-x-4">
              <p>LEAGUE</p>
              <img
                src={champions}
                alt="partners"
                className="h-8 w-8 tab:h-20 tab:w-20 rounded-md tab:rounded-3xl"
              />
            </div>
          </div>
          <p className="max-w-sm text-base bug:text-lg pepe:text-xl font-spaceGroteskReg mx-auto px-6 tab:px-0 text-white">
            Mr Vaskange has partnered with the UEFA-Champions League, one of the
            most prestigious football tournaments in the world and the most
            prestigious club competition in European football
          </p>
        </div>
      </motion.div>

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn buttonType="actionCall" label="book free consultation" />
      </div>

      <motion.div
        variants={heroAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
        className="tab:flex justify-center items-center tab:px-4 pb-44 tab:space-x-20 bug:space-x-36 pepe:space-x-44"
      >
        <div>
          <video
            loop
            muted
            autoPlay
            playsInline
            src={
              "https://firebasestorage.googleapis.com/v0/b/webuidl-db.appspot.com/o/vaskange.mp4?alt=media&token=de830ce8-3368-490e-a73b-de7610762b6f"
            }
            className="h-64 w-64 tab:h-80 tab:w-80 bug:h-[28rem] bug:w-[28rem] rounded-3xl tab:block mx-auto"
            type="video/mp4"
          />
        </div>
        <div className="text-center tab:text-start pt-20 tab:pt-0 ">
          <div className="font-jersey text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl mb-10">
            <p>TURKISH</p>
            <div className=" flex tab:block justify-center  items-center space-x-4">
              <p>AIRLINES</p>
              <img
                src={turkish}
                alt="partners"
                className=" h-8 w-8 tab:h-20 tab:w-20 rounded-md tab:rounded-3xl"
              />
            </div>
          </div>
          <p className="max-w-sm text-base bug:text-lg pepe:text-xl font-spaceGroteskReg mx-auto px-6 tab:px-0 text-white">
            Mr Vaskange has partnered with Turkish Airlines, the largest
            mainline carrier in the world by number of passenger destinations
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default VaskangeMiddle;
