import React from "react";
import duck1 from "../../Assets/Art/2d18.jpg";
import duck2 from "../../Assets/Art/2d19.jpg";
import duck3 from "../../Assets/Art/2d20.jpg";
import duck4 from "../../Assets/Art/2d14.jpg";
import duck5 from "../../Assets/Art/2d13.jpg";
import duck6 from "../../Assets/Art/2d12.jpg";
import duck7 from "../../Assets/Art/2d11.jpg";
import duck8 from "../../Assets/Art/2d10.jpg";
import Marquee from "react-fast-marquee";

const duckies = [
  { id: 1, image: duck1 },
  { id: 2, image: duck2 },
  { id: 3, image: duck3 },
  { id: 4, image: duck4 },
  { id: 5, image: duck5 },
  { id: 6, image: duck6 },
  { id: 7, image: duck7 },
  { id: 8, image: duck8 },
];

const DuckiesSlide = () => {
  return (
    <div className="flex">
      {duckies.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const DuckiesMarquee = () => {
  return (
    <div>
      <Marquee gradient={false} speed={100}>
        <DuckiesSlide />
      </Marquee>
    </div>
  );
};

export default DuckiesMarquee;
