import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import PartnersTop from "../../Components/Partners/PartnersTop";
import VaskangeMiddle from "../../Components/Partners/Vaskange/VaskangeMiddle";

const Vaskange = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <PartnersTop />
      <VaskangeMiddle />
      <Footer />
    </div>
  );
};

export default Vaskange;
