import React from "react";
import Marquee from "react-fast-marquee";
import duck1 from "../../Assets/Illustrations/duck1.jpg";
import duck2 from "../../Assets/Illustrations/duck2.jpg";
import duck3 from "../../Assets/Illustrations/duck3.jpg";
import duck4 from "../../Assets/Illustrations/duck4.png";
import duck5 from "../../Assets/Illustrations/duck5.png";
import duck6 from "../../Assets/Illustrations/duck6.png";
import duck7 from "../../Assets/Illustrations/duck7.png";
import duck8 from "../../Assets/Illustrations/duck8.png";

const duckies = [
  { id: 1, image: duck1 },
  { id: 2, image: duck2 },
  { id: 3, image: duck3 },
  { id: 4, image: duck4 },
  { id: 5, image: duck5 },
  { id: 6, image: duck6 },
  { id: 7, image: duck7 },
  { id: 8, image: duck8 },
];

const DuckIllSlide = () => {
  return (
    <div className="flex">
      {duckies.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const DuckiesIllustrationMarquee = () => {
  return (
    <div className="pb-24">
      <Marquee gradient={false} speed={100}>
        <DuckIllSlide />
      </Marquee>
    </div>
  );
};

export default DuckiesIllustrationMarquee;
