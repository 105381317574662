import React from "react";
import { ReactComponent as TwitterIcon } from "../../Assets/Twitter.svg";
import logo from "../../Assets/logo.gif";

const Footer = () => {
  return (
    <div className="px-4 md:px-14 pb-8 max-w-screen-2xl mx-auto">
      <div className="flex items-center justify-center ">
        <div className="bg-black absolute z-20 px-4 md:px-8">
          <img src={logo} alt="FooterLogo" className="w-12 h-12  " />
        </div>
      </div>
      <hr className="relative z-0 border-gray-700 pb-10" />
      <div className="font-jersey text-sm ml:text-lg md:text-xl text-gray-300 flex items-center justify-between">
        ©️2023 WEBUIDL
        <a
          href="https://twitter.com/webuidl_"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon />
        </a>
      </div>
    </div>
  );
};

export default Footer;
