import React from "react";
import { motion } from "framer-motion";
import EthAnalyticsMarquee from "../Marquees/EthAnalyticsMarquee";
import eth7 from "../../Assets/Eth/Eth7.jpg";
import etherscan from "../../Assets/Eth/Etherscan.png";
import MoreFootageMarquee from "../Marquees/MoreFootageMarquee";
import osvol from "../../Assets/Eth/OSVol.jpg";
import contract from "../../Assets/Eth/smartcontract.png";
import PepelandMarquee from "../Marquees/PepelandMarquee";
import PepelandEcosystemMarquee from "../Marquees/PepelandEcosystemMarquee";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";

const heroAnimation = {
  offScreen: {
    opacity: 0,
    y: 225,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const EthMiddle = () => {
  return (
    <div className="bg-black text-white w-full">
      <div className="overflow-hidden">
        <div className="font-jersey text-xl ml:text-2xl tab:text-4xl space-y-6 text-center pb-20">
          <p>Trending N5 for 24 hours on Opensea</p>
          <p>Top 5 for 24 hours on X2Y2</p>
          <p>Blue chip owners with Pepeland</p>
          <p>Large Sales Count</p>
        </div>

        <EthAnalyticsMarquee />

        <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
          <CalendlyBtn
            buttonType="actionCall"
            label="consult us for your project"
          />
        </div>

        <motion.div
          variants={heroAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.2 }}
          className="font-jersey text-xl ml:text-2xl tab:text-5xl text-center px-10 space-y-10 pb-44"
        >
          <p className="px-6">
            Grossed more than <span className="font-sans">$</span>130 000 in
            profits within 8 days :{" "}
          </p>
          <img
            src={etherscan}
            alt="etherscan"
            className="rounded-3xl w-[50rem] mx-auto"
          />
        </motion.div>

        <motion.div
          variants={heroAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.3 }}
          className="flex flex-col tab:flex-row justify-center items-center  tab:px-4 tab:space-x-20 bug:space-x-28 pepe:space-x-72"
        >
          <div>
            <img
              src={eth7}
              alt="HeroImage"
              className="h-64  tab:h-80 bug:h-[30rem]  mx-auto border-2 border-white"
            />
          </div>
          <div className="text-center tab:text-start">
            <div className="font-jersey text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl mb-10 pt-20">
              <p>FIRST post</p>
            </div>
            <p className="max-w-sm text-base bug:text-lg pepe:text-xl font-jersey mx-auto px-6 tab:px-0 text-gray-400 ml-2">
              At the start of our marketing campaign our initial post landed an
              extensive outreach resulting in 267 000 impressions
            </p>
          </div>
        </motion.div>

        <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
          <CalendlyBtn buttonType="actionCall" label="book free consultation" />
        </div>

        <motion.div
          variants={fadeAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.5 }}
          className="font-jersey text-xl ml:text-2xl tab:text-5xl text-center px-10 space-y-10 pb-0"
        >
          <p>some of our marketing stunts</p>
        </motion.div>
        <MoreFootageMarquee />

        <motion.div
          variants={heroAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.3 }}
          className="font-jersey text-xl ml:text-2xl tab:text-5xl text-center px-10 space-y-10 pb-44 pt-44"
        >
          <p className="px-6">
            Volumed + <span className="font-sans">$</span>180 000
            <span className="font-sans">+</span> in secondary sales{" "}
          </p>
          <img
            src={osvol}
            alt="etherscan"
            className="rounded-3xl w-[50rem] mx-auto"
          />
        </motion.div>

        <motion.div
          variants={heroAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.3 }}
          className="font-jersey text-xl ml:text-2xl tab:text-5xl text-center px-10 space-y-10 pb-44 "
        >
          <p className="px-6">
            Branded Smart Contract <br /> under Pepeland Name :
          </p>
          <img
            src={contract}
            alt="etherscan"
            className="rounded-3xl w-[50rem] h-[20rem] mx-auto"
          />
        </motion.div>

        <motion.div
          variants={fadeAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.5 }}
          className="font-jersey text-xl ml:text-2xl tab:text-5xl text-center px-10 space-y-10 pb-0"
        >
          <p className="pb-10">some pepelands</p>
        </motion.div>
        <PepelandMarquee />

        <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
          <CalendlyBtn
            buttonType="actionCall"
            label="get a free consultation"
          />
        </div>

        <motion.div
          variants={fadeAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.5 }}
          className="font-jersey text-xl ml:text-2xl tab:text-5xl text-center px-10 space-y-10 pb-0 "
        >
          <p className="pb-10">
            Coming soon to <br /> the Pepeland ecosystem
          </p>
        </motion.div>
        <PepelandEcosystemMarquee />
      </div>
    </div>
  );
};

export default EthMiddle;
