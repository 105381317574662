import React from "react";
import TokensTop from "../../Components/Tokens/TokensTop";
import Header from "../../Components/Header/Header";
import TokensMiddle from "../../Components/Tokens/TokensMiddle";

const Tokens = () => {
  return (
    <div className="bg-black h-screen">
      <Header />
      <TokensTop />
      <TokensMiddle />
    </div>
  );
};

export default Tokens;
