import React from "react";
import Marquee from "react-fast-marquee";

const MoreFootageMarquee = () => {
  return (
    <div>
      <div className="pt-20">
        <Marquee gradient={false} speed={100} pauseOnHover={true}>
          <video
            loop
            muted
            autoPlay
            playsInline
            src={
              "https://firebasestorage.googleapis.com/v0/b/webuidl-db.appspot.com/o/morefootage1.mp4?alt=media&token=14270b4b-e796-439f-aea6-58b3d0d01376"
            }
            className="mx-auto border-2 border-white h-[20rem] tab:h-[30rem] "
            type="video/mp4"
          />
          <video
            loop
            muted
            autoPlay
            playsInline
            src={
              "https://firebasestorage.googleapis.com/v0/b/webuidl-db.appspot.com/o/morefootage2.mp4?alt=media&token=e9f7f101-de1e-45a8-a088-a3c861d85d3a"
            }
            className="mx-auto border-2 border-white h-[20rem] tab:h-[30rem] "
            type="video/mp4"
          />
          <video
            loop
            muted
            autoPlay
            playsInline
            src={
              "https://firebasestorage.googleapis.com/v0/b/webuidl-db.appspot.com/o/morefootage3.mp4?alt=media&token=5a2d9a93-8142-45b8-b935-c23061c83489"
            }
            className="mx-auto border-2 border-white h-[20rem] tab:h-[30rem] "
            type="video/mp4"
          />
        </Marquee>
      </div>
    </div>
  );
};

export default MoreFootageMarquee;
