import React from "react";
import pa1 from "../../Assets/PopArtist.png";
import pa2 from "../../Assets/HappyDuck.jpeg";
import Marquee from "react-fast-marquee";

const PopArtistMarquee = () => {
  return (
    <div className="pb-32">
      <Marquee gradient={false} speed={100}>
        <div className="px-10">
          {" "}
          <video
            loop
            muted
            autoPlay
            playsInline
            src={
              "https://firebasestorage.googleapis.com/v0/b/webuidl-db.appspot.com/o/HappyDuck.mp4?alt=media&token=43e95aa2-b65a-426b-8e8c-de195d571ae9"
            }
            className="  w-[22rem] h-[30rem] object-cover"
            type="video/mp4"
          />
        </div>
        <div className="px-10">
          <img src={pa1} alt="pop-artist" className="w-[22rem] h-[30rem]" />
        </div>
        <div className="px-10">
          <img src={pa2} alt="pop-artist" className="w-[22rem] h-[30rem]" />
        </div>
      </Marquee>
    </div>
  );
};

export default PopArtistMarquee;
