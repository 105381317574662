import React from "react";
import { motion } from "framer-motion";

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const ShowcaseTop = () => {
  return (
    <div className="bg-black pb-64 text-white font-jersey ">
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
      >
        <div className="text-center pt-28 text-7xl ml:text-8xl tab:text-9xl bug:text-[225px] font-jersey leading-none pb-32">
          <p>art</p>
          <p>showroom</p>
        </div>
        <div className="text-center font-jersey text-xl tab:text-2xl text-gray-500 pb-4">
          explore our
        </div>
        {/* <div className="flex justify-center items-center mx-auto space-x-4 tab:space-x-14 pb-28">
          <img
            src={Alto}
            alt="MarketplaceLogo"
            className="h-16 w-16 tab:h-24 tab:w-24"
          />
        </div> */}
        {/* <div className="text-center">COMING SOON TO</div> */}
        <div className="text-center px-4 tab:px-20 space-y-8">
          <p className="font-jersey text-3xl ml:text-4xl tab:text-5xl  ">
            various art styles
          </p>
          <p className="text-base tab:text-2xl font-spaceGroteskReg text-gray-400 px-2 max-w-7xl mx-auto">
            At WeBuidl Studios, we have been producing a full range of art, from
            2d characters all the way to anime-styled. From individual clients
            to blockchain clients, this a small sneak peek of our art produced
            since 2019 for our partners :
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default ShowcaseTop;
