import { useSpring, animated } from "react-spring";

export const NumberAnimation = ({ n }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });

  return <animated.div>{number.to((n) => n.toFixed(2))}</animated.div>;
};

export const NumberAnimationNoDecimals = ({ n }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10, duration: 1200 },
  });

  return <animated.div>{number.to((n) => n.toFixed())}</animated.div>;
};
