import React from "react";
import Fair from "../../../Assets/fair.jpg";
import { motion } from "framer-motion";
import { CalendlyBtn } from "../../CalendlyBtn/CalendlyBtn";

const heroAnimation = {
  offScreen: {
    opacity: 0,
    y: 225,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const FairxyzMiddle = () => {
  return (
    <div>
      <div className="text-white font-jersey flex justify-center items-center">
        <div>
          <div className=" text-7xl ml:text-8xl tab:text-9xl tab:mb-10 tab:pb-20 text-center">
            <p>fair.xyz</p>
          </div>

          <motion.div
            variants={heroAnimation}
            initial="offScreen"
            whileInView="onScreen"
            viewport={{ once: true, amount: 0.1 }}
            className="flex-col-reverse flex  tab:flex-row justify-center items-center tab:px-4  tab:space-x-20 bug:space-x-36"
          >
            <div className="text-center tab:text-start pt-10 tab:pt-0 px-6 tab:px-0">
              <p className="max-w-md text-base bug:text-lg pepe:text-2xl  mx-auto px-6 tab:px-0 text-white pb-6">
                Fair.xyz is one of the biggest no-code platform that allows
                creators to launch end-to-end NFT collections; secure,
                accessible and fair.
              </p>
              <p className="max-w-md text-base bug:text-lg pepe:text-2xl  mx-auto px-6 tab:px-0 text-white pb-6">
                With a 4.5M$ Seed round investment, Fair is backed by giant web3
                companies such as Opensea.
              </p>

              <div className="max-w-md text-base bug:text-lg pepe:text-2xl  mx-auto px-6 tab:px-0 text-white">
                <p className="pb-6 tab:pb-2">
                  {" "}
                  With the founders as ex-Goldman Sachs, ex-Instagram nfts
                  engineers, the platform already partnered with :
                </p>

                <div className="text-gray-300 text-base tab:text-lg">
                  <p>Polygon Labs</p>
                  <p>Redbull</p>
                  <p>
                    OSF<span className="font-sans font-bold">,</span> creator of
                    Rekt nft collection
                  </p>
                </div>
              </div>
            </div>

            <div>
              <img
                src={Fair}
                alt="fair-xyz-partner"
                className="h-64 w-64 tab:h-80 tab:w-80 bug:h-[28rem] bug:w-[28rem] rounded-3xl tab:block mx-auto mt-14 tab:mt-0"
              />
            </div>
          </motion.div>
        </div>
      </div>

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn
          buttonType="actionCall"
          label="consult with our experts free"
        />
      </div>
    </div>
  );
};

export default FairxyzMiddle;
