import React from "react";
import HomeVideo from "../../Assets/Video/Homepage.mp4";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";

const Landing = () => {
  return (
    <div className="bg-black text-white h-screen relative flex justify-center">
      <video
        loop
        muted
        autoPlay
        playsInline
        src={HomeVideo}
        className="object-cover w-full h-full"
        type="video/mp4"
      />

      <h1 className="absolute z-20 top-[200px]  text-7xl ml:text-8xl tab:text-9xl bug:text-[225px] font-jersey flex justify-center items-center shadowText">
        WEBUIDL
      </h1>

      <div className="tab:hidden absolute z-20 bottom-[200px] left-1/2 -translate-x-1/2 font-jersey">
        <CalendlyBtn buttonType="landing" label="Book free consultation" />
      </div>
    </div>
  );
};

export default Landing;
