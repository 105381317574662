import React from "react";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";

const TableHead = [
  { id: 1, title: "Name" },
  { id: 2, title: "Followers" },
  { id: 3, title: "Language" },
  { id: 4, title: "Format" },
];

const TableBody = [
  {
    id: 1,
    name: "Centro Cripto",
    followers: "61700",
    format: "Video review",
    language: "Spanish",
  },
  {
    id: 2,
    name: "Wael Salimi",
    followers: "131000",
    format: "Video review",
    language: "Arabic",
  },
  {
    id: 3,
    name: "Tony Crypto",
    followers: "15500",
    format: "Video review / Tg post",
    language: "French",
  },
  {
    id: 4,
    name: "Umut Aktu",
    followers: "72300",
    format: "Video review",
    language: "Turkish",
  },
  {
    id: 5,
    name: "Coin22",
    followers: "28800",
    format: "Video review",
    language: "Russian",
  },
];

const InfluencerTable = () => {
  const addCommasToIntegerString = (integerStr) => {
    if (!integerStr.match(/^\d+$/)) {
      return "Invalid input";
    }

    const parts = [];
    let part = "";
    let count = 0;

    for (let i = integerStr.length - 1; i >= 0; i--) {
      part = integerStr[i] + part;
      count++;

      if (count === 3 || i === 0) {
        parts.unshift(part);
        part = "";
        count = 0;
      }
    }

    return parts.join(",");
  };

  return (
    <div className="my-44 w-[90%] tab:w-[80%] mx-auto">
      <div className="text-center text-4xl font-jersey pb-10">
        Some of our influential experts
      </div>

      <div className="flex justify-center ">
        <div className="flex flex-col justify-center w-full">
          <div className="space-y-2">
            <p className="font-jersey text-lg">influencers</p>
            <p className="font-thin text-gray-300">
              With access to +40 Crypto Youtubers in 10+ different regions,
              Webuidl is able to provide you with an international and effecient
              campaign for your web3 project upon demand 📈
            </p>
          </div>

          <div className="flex pt-10 justify-between border-b border-gray-500 pb-3">
            {TableHead.map(({ id, title }, index) => (
              <div
                key={id}
                className={`font-jersey w-[25%] ${
                  index === 1
                    ? "hidden tab:flex justify-center"
                    : index === 2
                    ? "flex justify-center"
                    : index === 3
                    ? "flex justify-end"
                    : ""
                }`}
              >
                {title}
              </div>
            ))}
          </div>

          <div className="">
            {TableBody.map(({ id, name, language, followers, format }) => (
              <div
                key={id}
                className="flex  border-b border-gray-500 py-3 font-thin"
              >
                <span className="font-medium w-[33%] tab:w-[25%]">{name}</span>
                <span className="w-[33%] tab:w-[25%] hidden tab:flex justify-center">
                  {addCommasToIntegerString(followers)}
                </span>
                <span className="w-[33%] tab:w-[25%] flex justify-center">
                  {language}
                </span>
                {/* <CalendlyBtn
                  buttonType="influencerTable"
                  label="Get in touch"
                /> */}
                <span className="text-center w-[33%] tab:w-[25%] flex justify-end text-[#FFC825] font-normal">
                  {format}
                </span>
              </div>
            ))}
          </div>

          <div className="pt-10 font-jersey text-center">
            More Crypto Influencers upon demand
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerTable;
