import React from "react";
import pepetetris from "../../Assets/pepelandtetris.gif";
import pepeship from "../../Assets/pepeship.gif";
import Marquee from "react-fast-marquee";

const es = [
  { id: 1, image: pepetetris },
  { id: 2, image: pepeship },
];

const EsSlide = () => {
  return (
    <div className="flex">
      {es.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const PepelandEcosystemMarquee = () => {
  return (
    <div className="pb-24">
      <Marquee gradient={false} speed={100}>
        <EsSlide />
      </Marquee>
    </div>
  );
};

export default PepelandEcosystemMarquee;
