import React from "react";
import Marquee from "react-fast-marquee";
import penguin1 from "../../Assets/Process/penguin1.jpg";
import penguin2 from "../../Assets/Process/penguin2.jpg";
import penguin3 from "../../Assets/Process/penguin3.png";
import penguin4 from "../../Assets/Process/penguin4.png";
import penguin5 from "../../Assets/Process/penguin5.png";

const penguins = [
  { id: 1, image: penguin1 },
  { id: 2, image: penguin2 },
  { id: 3, image: penguin3 },
  { id: 4, image: penguin4 },
  { id: 5, image: penguin5 },
];

const PenguinSlide = () => {
  return (
    <div className="flex">
      {penguins.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const PenguinMarquee = () => {
  return (
    <div>
      <Marquee gradient={false} speed={100}>
        <PenguinSlide />
      </Marquee>
    </div>
  );
};

export default PenguinMarquee;
