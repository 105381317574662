import React from "react";
import { motion } from "framer-motion";

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const MarketingTop = () => {
  return (
    <div className="bg-black pb-44 text-white ">
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
      >
        <div className="text-center pt-28 text-7xl ml:text-8xl tab:text-9xl bug:text-[225px] font-jersey leading-none pb-32">
          <p>CREATIVE</p>
          <p>MARKETING</p>
        </div>
        {/* <div className="text-center font-jersey text-xl tab:text-2xl text-gray-500 pb-4">
          COMING SOON TO
        </div> */}
        <div className="text-center px-4 tab:px-20 space-y-8 text-base tab:text-2xl font-jersey max-w-7xl mx-auto">
          {/* <p className="font-jersey text-3xl ml:text-4xl tab:text-5xl  ">
            7 777 HABITANTS OF PEPELAND
          </p> */}
          <p className="text-xl ml:text-2xl tab:text-4xl">
            WeBuidl has an international network of partners in different
            industries, making it a powerhouse marketing agency for NFT project
            Owners.
          </p>

          <div className="text-xl ml:text-2xl tab:text-4xl">
            Here are some of our prowess{" "}
            <span className="font-sans font-medium text-3xl ml-2">:</span>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default MarketingTop;
