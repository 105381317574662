import React from "react";
import { motion } from "framer-motion";
import joepegs from "../../Assets/JoePegs.jpeg";
import AvaxVolMarquee from "../Marquees/AvaxVolMarquee";
import DuckiesMarquee from "../Marquees/DuckiesMarquee";
import DuckiesIllustrationMarquee from "../Marquees/DuckiesIllustrationMarquee";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";

const heroAnimation = {
  offScreen: {
    opacity: 0,
    y: 225,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const AvaxMiddle = () => {
  return (
    <div className="bg-black text-white w-full">
      <div className="overflow-hidden">
        <motion.div
          variants={heroAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.3 }}
          className="tab:flex justify-center items-center tab:px-4 pb-44"
        >
          <img
            src={joepegs}
            alt="joepegs-banner"
            className="rounded-3xl px-10"
          />
        </motion.div>

        <motion.div
          variants={fadeAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.1 }}
          className="font-jersey text-xl ml:text-2xl tab:text-4xl space-y-6 text-center pb-20"
        >
          <p>Number 2 by Volume Weekly on Joepegs</p>
          <p>Number 2 by Volume and Sales all Avalanche Marketplaces </p>
        </motion.div>
        <AvaxVolMarquee />

        <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
          <CalendlyBtn
            buttonType="actionCall"
            label="consult us for your project"
          />
        </div>

        <motion.div
          variants={fadeAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.1 }}
          className="font-jersey text-3xl tab:text-5xl space-y-6 text-center tab:pb-20 pb-10 "
        >
          <p>some duckies</p>
        </motion.div>
        <DuckiesMarquee />

        <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
          <CalendlyBtn buttonType="actionCall" label="book free consultation" />
        </div>

        <motion.div
          variants={fadeAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.2 }}
          className="font-jersey text-3xl tab:text-5xl space-y-6 text-center tab:pb-20 pb-10"
        >
          <p>Some Happy Ducks illustrations</p>
        </motion.div>
        <DuckiesIllustrationMarquee />
      </div>
    </div>
  );
};

export default AvaxMiddle;
