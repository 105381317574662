import React from "react";
import Marquee from "react-fast-marquee";
import process1 from "../../Assets/Process/Process1.jpg";
import process2 from "../../Assets/Process/Process2.jpg";
import process3 from "../../Assets/Process/Process3.jpg";
import process4 from "../../Assets/Process/Process4.jpg";

const duckies = [
  { id: 1, image: process1 },
  { id: 2, image: process2 },
  { id: 3, image: process3 },
  { id: 4, image: process4 },
];

const DuckTabletSlide = () => {
  return (
    <div className="flex">
      {duckies.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64  tab:h-80 bug:h-[30rem] md:rounded-3xl border-2 border-white "
            />
          </div>
        );
      })}
    </div>
  );
};

const DuckTabletsMarquee = () => {
  return (
    <div>
      <Marquee gradient={false} speed={100}>
        <DuckTabletSlide />
      </Marquee>
    </div>
  );
};

export default DuckTabletsMarquee;
