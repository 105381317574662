import React from "react";
import eth1 from "../../Assets/Eth/Eth1.jpeg";
import eth2 from "../../Assets/Eth/Eth2.jpeg";
import eth3 from "../../Assets/Eth/Eth3.png";
import eth4 from "../../Assets/Eth/Eth4.png";
import eth5 from "../../Assets/Eth/Eth5.jpeg";
import eth6 from "../../Assets/Eth/Eth6.jpg";
import Marquee from "react-fast-marquee";

const analytics = [
  { id: 1, image: eth1 },
  { id: 2, image: eth2 },
  { id: 3, image: eth3 },
  { id: 4, image: eth4 },
  { id: 5, image: eth5 },
  { id: 6, image: eth6 },
];

const EthSlide = () => {
  return (
    <div className="flex">
      {analytics.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const EthAnalyticsMarquee = () => {
  return (
    <div className="">
      <Marquee gradient={false} speed={100} pauseOnHover={true}>
        <EthSlide />
      </Marquee>
    </div>
  );
};

export default EthAnalyticsMarquee;
