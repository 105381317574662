import React from "react";
import Marquee from "react-fast-marquee";
import pepe1 from "../../Assets/pepeland1.gif";
import pepe2 from "../../Assets/pepeland2.gif";
import pepe3 from "../../Assets/pepeland3.gif";

const keks = [
  { id: 1, image: pepe1 },
  { id: 2, image: pepe2 },
  { id: 3, image: pepe3 },
];

const KekSlide = () => {
  return (
    <div className="flex">
      {keks.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const PepelandMarquee = () => {
  return (
    <div>
      <Marquee gradient={false} speed={100}>
        <KekSlide />
      </Marquee>
    </div>
  );
};

export default PepelandMarquee;
