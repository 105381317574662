import React from "react";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import metaverse1 from "../../Assets/Art/metaverse1.png";
import metaverse2 from "../../Assets/Art/metaverse2.png";
import metaverse3 from "../../Assets/Art/metaverse3.png";
import metaverse4 from "../../Assets/Art/metaverse4.png";
import metaverse5 from "../../Assets/Art/metaverse5.png";
import metaverse6 from "../../Assets/Art/metaverse6.png";
import metaverse7 from "../../Assets/Art/metaverse7.png";
import metaverse8 from "../../Assets/Art/metaverse8.png";
import metaverse9 from "../../Assets/Art/metaverse9.png";

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
      delay: 0.2,
    },
  },
};

const metaArt = [
  { id: 1, image: metaverse1 },
  { id: 2, image: metaverse2 },
  { id: 3, image: metaverse3 },
  { id: 4, image: metaverse4 },
  { id: 5, image: metaverse5 },
  { id: 6, image: metaverse6 },
  { id: 7, image: metaverse7 },
  { id: 8, image: metaverse8 },
  { id: 9, image: metaverse9 },
];
const MetaverseMarquee = () => {
  return (
    <div className="flex">
      {metaArt.map((nftAv) => {
        return (
          <div className="px-4">
            <img
              src={nftAv.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const MetaverseArtMarquee = () => {
  return (
    <div>
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
        className="font-jersey text-center text-7xl pb-20"
      >
        metaverse art
      </motion.div>
      <div className="space-y-10 pb-64">
        <Marquee gradient={false} speed={100}>
          <MetaverseMarquee />
        </Marquee>
        <Marquee gradient={false} speed={100} direction="right">
          <MetaverseMarquee />
        </Marquee>
      </div>
    </div>
  );
};

export default MetaverseArtMarquee;
