import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import PartnersTop from "../../Components/Partners/PartnersTop";
import VondoomMiddle from "../../Components/Partners/Vondoom/VondoomMiddle";

const Vondoom = () => {
  return (
    <div>
      <Header />
      <PartnersTop />
      <VondoomMiddle />
      <Footer />
    </div>
  );
};

export default Vondoom;
