import React from "react";
import Header from "../../Components/Header/Header";
import EthTop from "../../Components/Eth/EthTop";
import EthMiddle from "../../Components/Eth/EthMiddle";
import Footer from "../../Components/Footer/Footer";

const Eth = () => {
  return (
    <div className="text-white">
      <Header />
      <EthTop />
      <EthMiddle />
      <Footer />
    </div>
  );
};

export default Eth;
