import React from "react";
import ProcessTop from "../../Components/Art/Process/ProcessTop";
import ProcessMiddle from "../../Components/Art/Process/ProcessMiddle";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

const Process = () => {
  return (
    <div className="text-white bg-black">
      <Header />
      <ProcessTop />
      <ProcessMiddle />
      <Footer />
    </div>
  );
};

export default Process;
