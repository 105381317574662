import React from "react";
import PixelArtMarquee from "../../Marquees/PixelArtMarquee";
import MetaverseArtMarquee from "../../Marquees/MetaverseArtMarquee";
import TwoDArtMarquee from "../../Marquees/TwoDArtMarquee";
import AnimeArtMarquee from "../../Marquees/AnimeArtMarquee";
import AIArtMarquee from "../../Marquees/AIArtMarquee";
import { CalendlyBtn } from "../../CalendlyBtn/CalendlyBtn";

const ShowcaseMiddle = () => {
  return (
    <div className="">
      <PixelArtMarquee />

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn
          buttonType="actionCall"
          label="free consultation for your art"
        />
      </div>

      <MetaverseArtMarquee />

      <TwoDArtMarquee />

      <AnimeArtMarquee />

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn buttonType="actionCall" label="consult us for free" />
      </div>

      <AIArtMarquee />
    </div>
  );
};

export default ShowcaseMiddle;
