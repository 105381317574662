import React from "react";
import Marquee from "react-fast-marquee";
import duck1 from "../../Assets/Illustrations/duck1.jpg";
import duck2 from "../../Assets/Illustrations/duck2.jpg";
import duck3 from "../../Assets/Illustrations/duck3.jpg";
import duck4 from "../../Assets/Illustrations/duck4.png";
import duck5 from "../../Assets/Illustrations/duck5.png";
import duck6 from "../../Assets/Illustrations/duck6.png";
import duck7 from "../../Assets/Illustrations/duck7.png";
import duck8 from "../../Assets/Illustrations/duck8.png";
import ape1 from "../../Assets/Illustrations/ape1.jpg";
import ape2 from "../../Assets/Illustrations/ape2.jpg";
import ape3 from "../../Assets/Illustrations/ape3.jpg";
import ape4 from "../../Assets/Illustrations/ape4.jpg";
import ape5 from "../../Assets/Illustrations/ape5.jpg";
import ape6 from "../../Assets/Illustrations/ape6.png";
import duck9 from "../../Assets/Illustrations/duck9.png";
import duck10 from "../../Assets/Illustrations/duck10.png";
import duck11 from "../../Assets/Illustrations/duck11.png";
import duck12 from "../../Assets/Illustrations/duck12.png";
import duck13 from "../../Assets/Illustrations/duck13.jpg";
import duck14 from "../../Assets/Illustrations/duck14.jpg";
import duck15 from "../../Assets/Illustrations/ill.jpg";

const marquee1 = [
  { id: 1, image: duck1 },
  { id: 2, image: duck2 },
  { id: 3, image: duck3 },
  { id: 4, image: duck4 },
  { id: 5, image: duck5 },
  { id: 6, image: duck6 },
  { id: 7, image: duck7 },
  { id: 8, image: duck8 },
];

const marquee2 = [
  { id: 1, image: ape1 },
  { id: 2, image: ape2 },
  { id: 3, image: ape3 },
  { id: 4, image: ape4 },
  { id: 5, image: ape5 },
  { id: 6, image: ape6 },
];

const marquee3 = [
  { id: 9, image: duck9 },
  { id: 10, image: duck10 },
  { id: 11, image: duck11 },
  { id: 12, image: duck12 },
  { id: 13, image: duck13 },
  { id: 14, image: duck14 },
  { id: 15, image: duck15 },
];

const IllOne = () => {
  return (
    <div className="flex">
      {marquee1.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};
const IllTwo = () => {
  return (
    <div className="flex">
      {marquee2.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};
const IllThree = () => {
  return (
    <div className="flex">
      {marquee3.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};
const IllustrationMarquee = () => {
  return (
    <div className="space-y-6">
      <Marquee gradient={false} speed={100}>
        <IllOne />
      </Marquee>
      <Marquee gradient={false} speed={100} direction="right">
        <IllTwo />
      </Marquee>
      <Marquee gradient={false} speed={100}>
        <IllThree />
      </Marquee>
    </div>
  );
};

export default IllustrationMarquee;
