import React from "react";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import AI1 from "../../Assets/Art/AIart/AI1.jpg";
import AI2 from "../../Assets/Art/AIart/AI2.jpg";
import AI3 from "../../Assets/Art/AIart/AI3.jpg";
import AI4 from "../../Assets/Art/AIart/AI4.jpg";
import AI5 from "../../Assets/Art/AIart/AI5.jpg";
import AI6 from "../../Assets/Art/AIart/AI6.jpg";
import AI7 from "../../Assets/Art/AIart/AI7.jpg";
import AI8 from "../../Assets/Art/AIart/AI8.jpg";
import AI9 from "../../Assets/Art/AIart/AI9.jpg";
import AI10 from "../../Assets/Art/AIart/AI10.jpg";
import AI11 from "../../Assets/Art/AIart/AI11.jpg";
import AI12 from "../../Assets/Art/AIart/AI12.jpg";

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
      delay: 0.2,
    },
  },
};

const AIMarquee1 = [
  { id: 1, image: AI1 },
  { id: 2, image: AI2 },
  { id: 3, image: AI3 },
  { id: 4, image: AI4 },
  { id: 5, image: AI5 },
  { id: 6, image: AI6 },
];

const AIMarquee2 = [
  { id: 7, image: AI7 },
  { id: 8, image: AI8 },
  { id: 9, image: AI9 },
  { id: 10, image: AI10 },
  { id: 11, image: AI11 },
  { id: 12, image: AI12 },
];

const Marqx1 = () => {
  return (
    <div className="flex">
      {AIMarquee1.map((nftAv) => {
        return (
          <div className="px-4">
            <img
              src={nftAv.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const Marqx2 = () => {
  return (
    <div className="flex">
      {AIMarquee2.map((nftAv) => {
        return (
          <div className="px-4">
            <img
              src={nftAv.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const AIArtMarquee = () => {
  return (
    <div>
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
        className="font-jersey text-center text-7xl pb-20"
      >
        ai art
      </motion.div>
      <div className="space-y-10 pb-24 tab:pb-64">
        <Marquee gradient={false} speed={100}>
          <Marqx1 />
        </Marquee>
        <Marquee gradient={false} speed={100} direction="right">
          <Marqx2 />
        </Marquee>
      </div>
    </div>
  );
};

export default AIArtMarquee;
