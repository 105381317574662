import React from "react";
import { motion } from "framer-motion";
import MerchMarquee from "../Marquees/MerchMarquee";
import MoreFootageMarquee from "../Marquees/MoreFootageMarquee";
import { ReactComponent as IG } from "../../Assets/Instagram.svg";
import PopArtistMarquee from "../Marquees/PopArtistMarquee";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";

const heroAnimation = {
  offScreen: {
    opacity: 0,
    y: 225,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const MarketingMiddle = () => {
  return (
    <div className="bg-black text-white w-full">
      <div className="overflow-hidden">
        <motion.div
          variants={fadeAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.3 }}
          className="justify-center items-center   "
        >
          <div className="text-center pt-20 tab:pt-14 ">
            <div className="font-jersey text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl mb-10">
              <p>MERCH BRANDING</p>
            </div>
            <div className="space-y-6 max-w-4xl mx-auto font-jersey text-base bug:text-lg pepe:text-xl pb-20">
              Partnering with a Portugese-Canadian Street Fashion Photographer,
              Mr Joao, and a famous print-on-demand startup, we, and our clients
              can print merch (hoodies/tshirts) for them, and take photographs
              in beautiful spots in Portgual and Canada
            </div>
          </div>
        </motion.div>
        <MerchMarquee />

        <motion.div
          variants={heroAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.2 }}
          className=" justify-center items-center flex flex-col-reverse tab:flex-row tab:px-10  tab:space-x-10 bug:space-x-36 pepe:space-x-72"
        >
          <div className="text-center tab:text-start">
            <div className="font-jersey text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl mb-10 pt-20 tab:pt-0">
              <p>SKYLINE</p>
              <p>PILOT VIDEOS</p>
            </div>
            <p className="max-w-sm text-base bug:text-lg pepe:text-xl font-jersey mx-auto px-6 tab:px-0 ">
              Partnering with a vetted private plane pilot, our projects and our
              clients’ can now get private skyline videos for their art to boost
              their marketing internationally and in style !
            </p>
          </div>
          <div>
            <div className="flex justify-center ">
              <video
                loop
                muted
                autoPlay
                playsInline
                src={
                  "https://firebasestorage.googleapis.com/v0/b/webuidl-db.appspot.com/o/Flight.webm?alt=media&token=d55c1c4b-3a67-4cb5-8530-36fe5d1e387b"
                }
                className="mx-auto border-2 border-white h-[20rem] tab:h-[30rem] "
                type="video/mp4"
              />
            </div>
          </div>
        </motion.div>

        <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
          <CalendlyBtn
            buttonType="actionCall"
            label="get free custom marketing plan"
          />
        </div>

        <div className="pb-44">
          <motion.div
            variants={fadeAnimation}
            initial="offScreen"
            whileInView="onScreen"
            viewport={{ once: true, amount: 0.3 }}
            className=" text-center font-jersey text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl"
          >
            MORE FOOTAGE
          </motion.div>
          <MoreFootageMarquee />
        </div>

        <motion.div
          variants={fadeAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.3 }}
          className="flex flex-col  justify-center items-center pb-24 tab:px-4 "
        >
          <div className="text-center ">
            <div className="font-jersey text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl mb-10 pt-20">
              <p>NATIONAL POP</p>
              <p>ARTIST COLLABS</p>
            </div>
            <p className="max-w-5xl text-base bug:text-lg pepe:text-xl font-jersey mx-auto px-6  ">
              <p>
                {" "}
                Partnering with Mr Oussama Benabbou, a world known pop artist,
                an Art Academy of Paris rewarded artist, we re producing limited
                art pieces for our clients with projects with utilities.
              </p>

              <p className="mt-6">
                His Art sold in galleries in : Morocco- Spain- Dubai- United
                Arab Emirates- United States- Tukey- Argentina- France-
                Azerbaijan
              </p>
              <div className="mt-6  text-center flex justify-center items-center gap-4">
                <p>His Instagram:</p>
                <a
                  href="https://www.instagram.com/ous_popart/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IG className="h-8 w-8" />
                </a>
              </div>
            </p>
          </div>
        </motion.div>
        <PopArtistMarquee />

        <div className="flex justify-center  mb-20    tab:mb-32">
          <CalendlyBtn buttonType="actionCall" label="book free consultation" />
        </div>
      </div>
    </div>
  );
};

export default MarketingMiddle;
