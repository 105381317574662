import React from "react";
import TestemonialCard from "./TestemonialCard";

const testies = [
  {
    id: 1,
    client: "Owner CoinTelegraph France",
    rep: "Sarah De Lima",
    comment:
      "With Webuidl’s assistance for our new AI project, Ayman and his team could create for us a content campaign that lead to our social getting all-time high engagements, something I really value as a media owner myself.",
  },
  {
    id: 2,
    client: "Founders Bitcoin Minetrix",
    rep: "Finixio",
    comment:
      "Webuidl helped us drive Crypto KOL marketing in both France and MENA regions, with their help we increased our presale fund raising by almost 200% within the span of 5 months.",
  },
  {
    id: 3,
    client: "Business Dev, Binance",
    rep: "Mahmoud Jawwad",
    comment:
      "Ayman’s leadership could help us spearhead our Binance Academy Program faster than ever. His brilliant contacts and networks have gotten us direct Tech University partnerships for our blockchain and compliance program uni curriculum integrations.",
  },
  {
    id: 4,
    client: "CEO, BloK Capital",
    rep: "Sheetal Nehra",
    comment:
      "Having an investment fund management platform using ERC4337 was one of the toughest products to market with the Defi community.Webuidl could literally helped us grow to thousands in our communities and hundreds of our product users within less than 2 months of marketing. We’re now set to fundraise our presale token for almost a 2MM$ fund appreciation !",
  },
  {
    id: 5,
    client: "CEO, Sarge Universe",
    rep: "Steve Sarge",
    comment:
      "Web3 Gaming can be very wild, and its marketing needs a specific marketer who’s knowledgeable with the community. With WeBuidl not only they helped built our web3 game community from scratch, but allowed us to target more than 100 nft and defi community to partner with and strike our minting event in the most efficient way !",
  },
  {
    id: 6,
    client: "CEO, Coinbound.io",
    rep: "TY Smith",
    comment:
      "Webuidl partnered with us on some of the most complex tokenized projects, assisted us craft comprehensive and mathematical whitepapers with detailed tokenomics, and bring us advice to navigate the specifics of crypto tokens marketing, with their beautiful network of different DEXs and CEXs",
  },
];

const Testemonials = () => {
  return (
    <div className="mt-44">
      <p className="text-4xl my-4 font-jersey  text-center pb-10">
        What people are saying
      </p>
      <div className=" flex flex-wrap justify-center gap-4 px-8">
        {testies.map((testie) => (
          <TestemonialCard
            key={testie.id}
            index={testie.id}
            client={testie.client}
            rep={testie.rep}
            comment={testie.comment}
          />
        ))}
      </div>
    </div>
  );
};

export default Testemonials;
