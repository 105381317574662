import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
// import Header from "./Components/Header/Header";
import Clients from "./Routes/Clients/Clients";
import Home from "./Routes/Home/Home";
import MarketingCreativity from "./Routes/Marketing/MarketingCreativity";
import Eth from "./Routes/OurProjects/Eth";
import Avax from "./Routes/OurProjects/Avax";
import Process from "./Routes/OurArt/Process";
import Showcase from "./Routes/OurArt/Showcase";
import Vondoom from "./Routes/Partners/Vondoom";
import Vaskange from "./Routes/Partners/Vaskange";
import Fairxyz from "./Routes/Partners/Fairxyz";
import Tokens from "./Routes/OurProjects/Tokens";

function App() {
  return (
    <div className=" bg-black">
      <BrowserRouter>
        <ScrollToTop />
        {/* <Header /> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="clients" element={<Clients />} />
          <Route exact path="partners/vondoom" element={<Vondoom />} />
          <Route exact path="partners/vaskange" element={<Vaskange />} />
          <Route exact path="partners/fairxyz" element={<Fairxyz />} />
          <Route
            exact
            path="marketing-creativity"
            element={<MarketingCreativity />}
          />
          <Route
            exact
            path="our-art/process-and-illustrations"
            element={<Process />}
          />
          <Route exact path="our-art/showroom" element={<Showcase />} />
          <Route exact path="our-projects/ethereum" element={<Eth />} />
          <Route exact path="our-projects/avalanche" element={<Avax />} />
          <Route exact path="our-projects/tokens" element={<Tokens />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
