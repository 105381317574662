import React from "react";
import minetrixLogo from "../../Assets/BitcoinMinetrix/logo.svg";
import phoneMock from "../../Assets/BitcoinMinetrix/phoneMock2.png";
import raisedFunds from "../../Assets/BitcoinMinetrix/fundsRaised.png";
import Marquee from "react-fast-marquee";
import mintrixCompany1 from "../../Assets/BitcoinMinetrix/company1.svg";
import mintrixCompany2 from "../../Assets/BitcoinMinetrix/company2.svg";
import mintrixCompany3 from "../../Assets/BitcoinMinetrix/company3.svg";
import mintrixCompany4 from "../../Assets/BitcoinMinetrix/company4.svg";
import mintrixCompany5 from "../../Assets/BitcoinMinetrix/company5.svg";
import mintrixCompany6 from "../../Assets/BitcoinMinetrix/company6.svg";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";
import mklogo from "../../Assets/MemeKombat/logo.png";
import mklogo2 from "../../Assets/MemeKombat/logo2.png";
import mkpresale from "../../Assets/MemeKombat/presale.png";
import mong from "../../Assets/MemeKombat/mong.png";

const minetrixCompanies = [
  { id: 1, image: mintrixCompany1 },
  { id: 2, image: mintrixCompany2 },
  { id: 3, image: mintrixCompany3 },
  { id: 4, image: mintrixCompany4 },
  { id: 5, image: mintrixCompany5 },
  { id: 6, image: mintrixCompany6 },
  { id: 7, image: mintrixCompany1 },
  { id: 8, image: mintrixCompany2 },
  { id: 9, image: mintrixCompany3 },
  { id: 10, image: mintrixCompany4 },
  { id: 11, image: mintrixCompany5 },
  { id: 12, image: mintrixCompany6 },
];

const memeKombatStats = [
  { id: 1, title: "Total $MK Staked", value: "33,091,066" },
  { id: 2, title: "percent of $MK Staked", value: "80%" },
  { id: 3, title: "Total Rewards Paid", value: "7,005,708" },
  { id: 4, title: "Total Stakers", value: "10939" },
  { id: 5, title: "Total $MK Staked", value: "33,091,066" },
  { id: 6, title: "percent of $MK Staked", value: "80%" },
  { id: 7, title: "Total Rewards Paid", value: "7,005,708" },
  { id: 8, title: "Total Stakers", value: "10939" },
  { id: 9, title: "Total $MK Staked", value: "33,091,066" },
  { id: 10, title: "percent of $MK Staked", value: "80%" },
  { id: 11, title: "Total Rewards Paid", value: "7,005,708" },
  { id: 12, title: "Total Stakers", value: "10939" },
];

const TokensMiddle = () => {
  return (
    <div className="bg-black text-white w-full font-jersey">
      <div className="flex justify-center items-center gap-2 pb-10">
        <p className=" text-4xl tab:text-6xl ">bitcoin minetrix</p>
        <img
          src={minetrixLogo}
          alt="logo"
          className="h-10 w-10 tab:h-14 tab:w-14"
        />
      </div>

      <div className="flex flex-col bug:flex-row items-center justify-between px-4 bug:px-24  gap-10 max-w-[1500px] mx-auto">
        <div className="space-y-6 text-center bug:text-start pt-14 bug:pt-0">
          <p className="text-3xl tab:text-4xl ">
            stake tokens. <span className="text-[#FF8E34]">mine bitcoin</span>
          </p>
          <div className="text-gray-300 max-w-xl space-y-4">
            <p>
              Bitcoin Minetrix is a tokenized cloud mining platform that allows
              everyday people to mine bitcoin{" "}
              <span className="font-bold font-sans">(</span>BTC
              <span className="font-bold font-sans">)</span> in a decentralized
              way. Tokenizing cloud mining to ensure a secure and transparent
              experience.
            </p>
            <p>
              Solving a huge problem by removing the risk of third party cloud
              mining scams and putting the control into the hands of token
              holders. Simply stake BTCMTX to earn credits
              <span className="font-bold font-sans">,</span> and use these to
              mine BTC.
            </p>
          </div>
        </div>
        <img src={phoneMock} alt="minetrix" className="" />
      </div>

      <div className="border-y border-gray-500">
        <Marquee gradient={false} speed={100}>
          <div className="flex gap-20">
            {minetrixCompanies.map((company) => {
              return (
                <div key={company.id} className="px-4 py-10">
                  <img src={company.image} alt="avatar" />
                </div>
              );
            })}
          </div>
        </Marquee>
      </div>

      <div className="flex flex-col bug:flex-row-reverse items-center justify-between gap-20 px-4 bug:px-24  mt-24 max-w-[1500px] mx-auto">
        <div className="space-y-6 text-center bug:text-start">
          <p className="text-3xl tab:text-4xl">
            <span className="font-sans font-bold ">+</span> 10 000 000
            <span className="font-sans font-bold ">$</span> Raised for bitcoin
            minetrix
          </p>
          <p className="text-gray-300 max-w-xl">
            Thursday<span className="font-bold font-sans">,</span> February 1
            <span className="font-bold font-sans">,</span> 2024 – Innovative BTC
            cloud mining tokenization startup Bitcoin Minetrix{" "}
            <span className="font-bold font-sans">(</span>$BTCMTX
            <span className="font-bold font-sans">)</span> has received over $10
            million from project contributors. As the crypto community gains
            interest in its unique selling point
            <span className="font-bold font-sans">,</span> the Bitcoin Minetrix
            ICO is gaining momentum.
          </p>
        </div>
        <img src={raisedFunds} alt="minetrix" className="" />
      </div>

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn
          buttonType="actionCall"
          label="get free custom marketing plan"
        />
      </div>

      <div className="flex justify-center items-center gap-3 pb-14">
        <p className=" text-4xl tab:text-6xl ">meme kombat</p>
        <img src={mklogo} alt="logo" className="h-10 w-10 tab:h-20 tab:w-20" />
      </div>

      <div className="flex flex-col bug:flex-row items-center justify-between px-4 bug:px-24  gap-10 max-w-[1500px] mx-auto">
        <div className="space-y-6 text-center bug:text-start pt-14 bug:pt-0">
          <p className="text-3xl tab:text-4xl ">
            stake<span className="font-bold font-sans">,</span> play{" "}
            <span className="font-bold font-sans">&</span> get rewards
          </p>
          <p className="text-gray-300 max-w-xl">
            Meme Kombat is an innovative new gaming platform designed for gaming
            enthusiasts. From active betting to passive staking
            <span className="font-bold font-sans">,</span> there are rewards for
            all users.
          </p>
        </div>
        <img src={mong} alt="minetrix" className="bug:w-[40%]" />
      </div>
      <div className="border-y border-gray-500 bg-[#175259]">
        <Marquee gradient={false} speed={100} direction="right">
          <div className="flex">
            {memeKombatStats.map((stats) => {
              return (
                <div
                  key={stats.id}
                  className="px-20 py-8 flex flex-col items-center"
                >
                  <p className="text-gray-200">{stats.title}</p>
                  <p className="text-3xl tab:text-6xl">{stats.value}</p>
                </div>
              );
            })}
          </div>
        </Marquee>
      </div>

      <div className="flex flex-col bug:flex-row-reverse items-center justify-between gap-20 px-4 bug:px-24  mt-24 max-w-[1500px] mx-auto pb-44">
        <div className="space-y-6 text-center bug:text-start">
          <p className="text-3xl tab:text-4xl">
            <span className="font-sans font-bold ">+</span> 8 000 000
            <span className="font-sans font-bold ">$</span> Raised for meme
            kombat
          </p>
          <p className="text-gray-300 max-w-xl">
            Meme Kombat <span className="font-sans font-bold ">($</span>MK
            <span className="font-sans font-bold ">)</span> managed to raise
            almost $8 million for its e-gaming ecosystem. The latter will see
            meme characters clash in its combat arena. Meme Kombat raises $8
            million Web3 gaming platform a first in meme fighting.
          </p>
        </div>
        <img src={mkpresale} alt="minetrix" className="bug:w-[40%]" />
      </div>

      <div className="flex flex-col bug:flex-row bug:justify-between bug:gap-0 gap-10 items-center px-4 bug:px-10">
        <div className="flex flex-col items-center gap-10">
          <div className="text-3xl text-center flex flex-col gap-4">
            <p>
              50<span className="font-sans font-bold ">%</span> presale
            </p>
            <p className="text-gray-300 max-w-lg mx-auto font-sans text-xl">
              Half of total token supply will be set aside for the $MK presale.
              The intention is for all who buy presale to participate in Meme
              Kombat
            </p>
          </div>

          <div className="h-[0.5px] bg-gray-500 w-[300px]" />

          <div className="text-3xl text-center flex flex-col gap-4">
            <p>
              30<span className="font-sans font-bold ">%</span> staking{" "}
              <span className="font-sans font-bold ">&</span> battle rewards
            </p>
            <p className="text-gray-300 max-w-lg mx-auto font-sans text-xl">
              We have set aside a large percentage of the supply to support that
              ecosystem and help maintain balance
            </p>
          </div>
        </div>

        <img src={mklogo2} lat="meme-kombat-logo" className="w-[350px]" />

        <div className="flex flex-col items-center gap-10">
          <div className="text-3xl text-center flex flex-col gap-4">
            <p>
              10<span className="font-sans font-bold ">%</span> dex
            </p>
            <p className="text-gray-300 max-w-lg mx-auto font-sans text-xl">
              10<span className="font-sans font-bold ">%</span> of the overall
              supply is reserved for pairing with a liquidity pool on a
              decentralized exchange.
            </p>
          </div>

          <div className="h-[0.5px] bg-gray-500 w-[300px]" />

          <div className="text-3xl text-center flex flex-col gap-4">
            <p>
              10<span className="font-sans font-bold ">%</span> community
              rewards
            </p>
            <p className="text-gray-300 max-w-lg mx-auto font-sans text-xl">
              <span className="font-sans font-bold ">$</span>MK is all about
              participation and community engagement through the love of epic
              battles. So we have set aside a good amount of tokens for
              community rewards.
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-20 pb-20  tab:mt-32  tab:pb-32">
        <CalendlyBtn buttonType="actionCall" label="build your own project" />
      </div>
    </div>
  );
};

export default TokensMiddle;
