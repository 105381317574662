import React from "react";
import { motion } from "framer-motion";
import DuckTabletsMarquee from "../../Marquees/DuckTabletsMarquee";
import PenguinMarquee from "../../Marquees/PenguinMarquee";
import IllustrationMarquee from "../../Marquees/IllustrationMarquee";
import { CalendlyBtn } from "../../CalendlyBtn/CalendlyBtn";

// const heroAnimation = {
//   offScreen: {
//     opacity: 0,
//     y: 225,
//   },
//   onScreen: {
//     y: 0,
//     opacity: 100,
//     transition: {
//       ease: "easeIn",
//       duration: 0.5,
//     },
//   },
// };

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const ProcessMiddle = () => {
  return (
    <div className="bg-black text-white w-full font-jersey">
      <div className="pb-44">
        <p className="text-center pb-14 text-3xl ml:text-5xl tab:text-6xl">
          Happy Ducks Process
        </p>
        <DuckTabletsMarquee />
      </div>

      <motion.p
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
        className="text-center pb-14 text-3xl ml:text-5xl tab:text-6xl"
      >
        GNG Penguin Process
      </motion.p>
      <PenguinMarquee />

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn
          buttonType="actionCall"
          label="free consultation for your art"
        />
      </div>

      <div className="">
        <motion.p
          variants={fadeAnimation}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.3 }}
          className="text-center pb-14 text-3xl ml:text-5xl tab:text-6xl"
        >
          our illustrations
        </motion.p>
        <IllustrationMarquee />
      </div>

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn
          buttonType="actionCall"
          label="consult us for your concept"
        />
      </div>

      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
      >
        <div className="flex justify-center items-center  pb-24 tab:pb-44">
          <div>
            <p className="text-3xl mm:text-4xl bug:text-6xl pepe:text-7xl pb-10 tab:pb-20 font-jersey text-center px-10">
              where concept is brought to life
            </p>
            <video
              loop
              muted
              autoPlay
              playsInline
              src={
                "https://firebasestorage.googleapis.com/v0/b/webuidl-db.appspot.com/o/Process.mp4?alt=media&token=62733cad-7904-447b-a759-312c3f65d17c"
              }
              className="mx-auto h-[20rem] tab:h-[30rem] bug:h-[40rem]"
              type="video/mp4"
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ProcessMiddle;
