import React from "react";
import Marquee from "react-fast-marquee";
import advisor1 from "../../Assets/vondoom1.png";
import advisor2 from "../../Assets/vondoom2.png";
import advisor3 from "../../Assets/vondoom3.png";
import advisor4 from "../../Assets/vondoom4.png";
import advisor5 from "../../Assets/vondoom5.png";
import advisor6 from "../../Assets/vondoom6.png";

const advisor = [
  { id: 1, image: advisor1 },
  { id: 2, image: advisor2 },
  { id: 3, image: advisor3 },
  { id: 4, image: advisor4 },
];

const AdvisorSlide = () => {
  return (
    <div className="flex">
      {advisor.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-72 tab:h-80 tab:w-96 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const VonAdvisorMarquee = () => {
  return (
    <div className="pb-24">
      <Marquee gradient={false} speed={100}>
        <AdvisorSlide />
      </Marquee>
    </div>
  );
};

export default VonAdvisorMarquee;
