import React from "react";
import ShowcaseTop from "../../Components/Art/Showcase/ShowcaseTop";
import ShowcaseMiddle from "../../Components/Art/Showcase/ShowcaseMiddle";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const Showcase = () => {
  return (
    <div className="text-white bg-black">
      <Header />
      <ShowcaseTop />
      <ShowcaseMiddle />
      <Footer />
    </div>
  );
};

export default Showcase;
