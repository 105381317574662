import React from "react";
import Marquee from "react-fast-marquee";
import merch1 from "../../Assets/Merch/merch1.jpeg";
import merch2 from "../../Assets/Merch/merch2.jpeg";
import merch3 from "../../Assets/Merch/merch3.jpeg";
import merch4 from "../../Assets/Merch/merch4.jpeg";
import merch5 from "../../Assets/Merch/merch5.jpeg";
import merch6 from "../../Assets/Merch/merch6.jpeg";

const merch = [
  { id: 1, image: merch1 },
  { id: 2, image: merch2 },
  { id: 3, image: merch3 },
  { id: 4, image: merch4 },
  { id: 5, image: merch5 },
  { id: 6, image: merch6 },
];
const MerchSlide = () => {
  return (
    <div className="flex">
      {merch.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const MerchMarquee = () => {
  return (
    <div className="pb-44">
      <Marquee gradient={false} speed={100}>
        <MerchSlide />
      </Marquee>
    </div>
  );
};

export default MerchMarquee;
