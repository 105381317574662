import React from "react";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import twod11 from "../../Assets/Art/2d1.png";
import twod20 from "../../Assets/Art/2d2.png";
import twod13 from "../../Assets/Art/2d3.png";
import twod21 from "../../Assets/Art/2d4.png";
import twod5 from "../../Assets/Art/2d5.png";
import twod16 from "../../Assets/Art/2d6.png";
import twod7 from "../../Assets/Art/2d7.png";
import twod8 from "../../Assets/Art/2d8.png";
import twod9 from "../../Assets/Art/2d9.png";
import twod10 from "../../Assets/Art/2d10.jpg";
import twod1 from "../../Assets/Art/2d11.jpg";
import twod12 from "../../Assets/Art/2d12.jpg";
import twod3 from "../../Assets/Art/2d13.jpg";
import twod14 from "../../Assets/Art/2d14.jpg";
import twod15 from "../../Assets/Art/2d15.jpg";
import twod6 from "../../Assets/Art/2d16.jpg";
import twod17 from "../../Assets/Art/2d17.jpg";
import twod18 from "../../Assets/Art/2d18.jpg";
import twod19 from "../../Assets/Art/2d19.jpg";
import twod2 from "../../Assets/Art/2d20.jpg";
import twod4 from "../../Assets/Art/2d21.jpeg";
import twod22 from "../../Assets/Art/2d22.jpeg";
import twod23 from "../../Assets/Art/2d23.jpeg";
import twod24 from "../../Assets/Art/2d24.jpeg";
import twod25 from "../../Assets/Art/2d25.jpeg";
import twod26 from "../../Assets/Art/2d26.jpeg";

const twoDArt = [
  { id: 1, image: twod1 },
  { id: 2, image: twod2 },
  { id: 3, image: twod3 },
  { id: 4, image: twod4 },
  { id: 5, image: twod5 },
  { id: 6, image: twod6 },
  { id: 7, image: twod7 },
  { id: 8, image: twod8 },
  { id: 9, image: twod9 },
  { id: 10, image: twod10 },
  { id: 11, image: twod11 },
  { id: 12, image: twod12 },
  { id: 13, image: twod13 },
];
const twoDArt2 = [
  { id: 14, image: twod14 },
  { id: 15, image: twod15 },
  { id: 16, image: twod16 },
  { id: 17, image: twod17 },
  { id: 18, image: twod18 },
  { id: 19, image: twod19 },
  { id: 20, image: twod20 },
  { id: 21, image: twod21 },
  { id: 22, image: twod22 },
  { id: 23, image: twod23 },
  { id: 24, image: twod24 },
  { id: 25, image: twod25 },
  { id: 26, image: twod26 },
];

const TwoDMarquee = () => {
  return (
    <div className="flex">
      {twoDArt.map((nftAv) => {
        return (
          <div className="px-4">
            <img
              src={nftAv.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};
const TwoDMarquee2 = () => {
  return (
    <div className="flex">
      {twoDArt2.map((nftAv) => {
        return (
          <div className="px-4">
            <img
              src={nftAv.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
      delay: 0.2,
    },
  },
};

const TwoDArtMarquee = () => {
  return (
    <div>
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
        className="font-jersey text-center text-7xl pb-20"
      >
        2d art
      </motion.div>
      <div className="space-y-10 pb-64">
        <Marquee gradient={false} speed={100}>
          <TwoDMarquee />
        </Marquee>
        <Marquee gradient={false} speed={100} direction="right">
          <TwoDMarquee2 />
        </Marquee>
      </div>
    </div>
  );
};

export default TwoDArtMarquee;
