import React from "react";
import HomeHeader from "../../Components/Header/HomeHeader";
import Landing from "../../Components/Landing/Landing";
import CompaniesHelped from "../../Components/CompaniesHelped/CompaniesHelped";
import Statistics from "../../Components/Statistics/Statistics";
import Testemonials from "../../Components/Testemonials/Testemonials";
import InfluencerTable from "../../Components/InfluencerTable/InfluencerTable";

const Home = () => {
  return (
    <div className="text-white ">
      {/* LANDING MARQUEE IN HOME HEADER */}
      <HomeHeader />
      <Landing />

      <div className="py-24 border-t-[1px] border-white">
        <Statistics />
        <CompaniesHelped />
        <InfluencerTable />
        <Testemonials />
      </div>
    </div>
  );
};

export default Home;
