import React from "react";
import { motion } from "framer-motion";

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};
const PartnersTop = () => {
  return (
    <div className="bg-black text-white pb-32 tab:pb-64">
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
      >
        <div className="text-center pt-28 text-7xl ml:text-8xl tab:text-9xl bug:text-[225px] font-jersey leading-none pb-32">
          <p>our</p>
          <p>partners</p>
        </div>
        {/* <div className="text-center font-jersey text-xl tab:text-2xl text-gray-500 pb-4">
          COMING SOON TO
        </div> */}
        {/* <div className="flex justify-center items-center mx-auto space-x-4 tab:space-x-14 pb-28">
                  <img
                    src={Alto}
                    alt="MarketplaceLogo"
                    className="h-16 w-16 tab:h-24 tab:w-24"
                  />
                </div> */}
        {/* <div className="text-center">COMING SOON TO</div> */}
        <div className="text-center px-4 tab:px-20 space-y-8">
          <p className="font-jersey max-w-5xl text-3xl ml:text-4xl tab:text-5xl mx-auto ">
            At WeBuidl Studios, our network of partners is fully open and
            available for our Entreprise Clients :
          </p>
          {/* <p className="text-base tab:text-2xl font-spaceGroteskReg text-gray-400 px-2 max-w-7xl mx-auto">
            In Ancient History books, Scholars found many traces of the very
            first world civilization... The Peperis. Pepeland was once a land
            full of joy, fun and love. However to this day nobody knows anything
            about its location or its habitants. Our plane recently landed in an
            undiscovered territory. Who lives in this Promised Land ?
          </p> */}
        </div>
      </motion.div>
    </div>
  );
};

export default PartnersTop;
