import React from "react";
import { motion } from "framer-motion";
import client1 from "../../Assets/ClientsSection/client1.png";
import client2 from "../../Assets/ClientsSection/client2.png";
import client3 from "../../Assets/ClientsSection/client3.png";
import client4 from "../../Assets/ClientsSection/client4.png";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";

const heroAnimation = {
  offScreen: {
    opacity: 0,
    y: 225,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const ClientsMiddle = () => {
  return (
    <div className="bg-black text-white  w-full pb-24 flex flex-col justify-center items-center  px-10 overflow-hidden font-jersey">
      <motion.div
        variants={heroAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
      >
        <img src={client1} alt="clients" className="rounded-3xl w-[50rem]" />
        <p className="text-3xl mm:text-4xl font-jersey bug:text-6xl pepe:text-7xl pt-10 ml-4">
          Chainlink
        </p>
        <p className="max-w-3xl ml-4 pt-4">
          September 2021, ChainLink Labs team reached out to us as an nft
          project to discuss the various utilities of their VRF technology
          regarding metadata protection, and how we can spread it among other
          collections
        </p>
      </motion.div>

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn buttonType="actionCall" label="book free consultation" />
      </div>
      <motion.div
        variants={heroAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
        className=" mb-20 tab:mb-32"
      >
        <img src={client2} alt="clients" className="rounded-3xl w-[50rem]" />
        <p className="text-3xl mm:text-4xl font-jersey bug:text-6xl pepe:text-7xl pt-10 ml-4">
          GNG
        </p>
        <p className="max-w-3xl ml-4 pt-4">
          One of the biggest MLM corporations, WeBuidl Studios helped the GNG
          team navigate web3, create their own collection, and help them
          distribute it to their clients/participants.
        </p>
      </motion.div>

      <motion.div
        variants={heroAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
      >
        <img src={client3} alt="clients" className="rounded-3xl w-[50rem]" />
        <p className="text-3xl mm:text-4xl font-jersey bug:text-6xl pepe:text-7xl pt-10 ml-4">
          Polemix
        </p>
        <p className="max-w-3xl ml-4 pt-4">
          One of the few marketplaces aiming to support thinkers and from
          world-renowned opinion leaders on Polemix
        </p>
      </motion.div>

      <div className="flex justify-center mt-20 mb-20  tab:mt-32  tab:mb-32">
        <CalendlyBtn
          buttonType="actionCall"
          label="receive custom client strategy"
        />
      </div>

      <motion.div
        variants={heroAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
      >
        <img src={client4} alt="clients" className="rounded-3xl w-[50rem]" />
        <p className="text-3xl mm:text-4xl font-jersey bug:text-6xl pepe:text-7xl pt-10 ml-4">
          Polemix
        </p>
        <p className="max-w-3xl ml-4 pt-4">
          Co-founded by Ben Atnipp, an ex-Regional Manager at Uber, WeBuidl
          Studios helped Polemix navigate their market study by gathering
          multiple web3 users to conduct user test interviews
        </p>
      </motion.div>
    </div>
  );
};

export default ClientsMiddle;
