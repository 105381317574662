import React from "react";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import pixel11 from "../../Assets/Art/pixel1.gif";
import pixel20 from "../../Assets/Art/pixel2.gif";
import pixel13 from "../../Assets/Art/pixel3.gif";
import pixel21 from "../../Assets/Art/pixel4.gif";
import pixel5 from "../../Assets/Art/pixel5.gif";
import pixel16 from "../../Assets/Art/pixel6.gif";
import pixel7 from "../../Assets/Art/pixel7.gif";
import pixel8 from "../../Assets/Art/pixel8.jpg";
import pixel9 from "../../Assets/Art/pixel9.png";
import pixel10 from "../../Assets/Art/pixel10.png";
import pixel1 from "../../Assets/Art/pixel11.png";
import pixel12 from "../../Assets/Art/pixel12.png";
import pixel3 from "../../Assets/Art/pixel13.png";
import pixel14 from "../../Assets/Art/pixel14.png";
import pixel15 from "../../Assets/Art/pixel15.png";
import pixel6 from "../../Assets/Art/pixel16.png";
import pixel17 from "../../Assets/Art/pixel17.png";
import pixel18 from "../../Assets/Art/pixel18.png";
import pixel19 from "../../Assets/Art/pixel19.png";
import pixel2 from "../../Assets/Art/pixel20.png";
import pixel4 from "../../Assets/Art/pixel21.png";

const pixelArt = [
  { id: 1, image: pixel1 },
  { id: 2, image: pixel2 },
  { id: 3, image: pixel3 },
  { id: 4, image: pixel4 },
  { id: 5, image: pixel5 },
  { id: 6, image: pixel6 },
  { id: 7, image: pixel7 },
  { id: 8, image: pixel8 },
  { id: 9, image: pixel9 },
];
const pixelArt2 = [
  { id: 10, image: pixel10 },
  { id: 11, image: pixel11 },
  { id: 12, image: pixel12 },
  { id: 13, image: pixel13 },
  { id: 14, image: pixel14 },
  { id: 15, image: pixel15 },
  { id: 16, image: pixel16 },
  { id: 17, image: pixel17 },
  { id: 18, image: pixel18 },
  { id: 19, image: pixel19 },
  { id: 20, image: pixel20 },
  { id: 21, image: pixel21 },
];

const PixelMarquee = () => {
  return (
    <div className="flex">
      {pixelArt.map((nftAv) => {
        return (
          <div className="px-4">
            <img
              src={nftAv.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};
const PixelMarquee2 = () => {
  return (
    <div className="flex">
      {pixelArt2.map((nftAv) => {
        return (
          <div className="px-4">
            <img
              src={nftAv.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
      delay: 0.2,
    },
  },
};

const PixelArtMarquee = () => {
  return (
    <div>
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
        className="font-jersey text-center text-7xl pb-20"
      >
        pixel art
      </motion.div>
      <div className="space-y-10 ">
        <Marquee gradient={false} speed={100}>
          <PixelMarquee />
        </Marquee>
        <Marquee gradient={false} speed={100} direction="right">
          <PixelMarquee2 />
        </Marquee>
      </div>
    </div>
  );
};

export default PixelArtMarquee;
