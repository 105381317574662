import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";
import { motion } from "framer-motion";
import { ReactComponent as TwitterIcon } from "../../Assets/Twitter.svg";
import { ReactComponent as DropDown } from "../../Assets/DropDown.svg";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";

const sidebarAnimation = {
  offScreen: {
    height: 0,
    opacity: 0,
  },
  onScreen: {
    height: 270,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.3,
    },
  },
};

const Sidebar = () => {
  const [isOpen, setOpen] = useState(false);
  const [isArtClicked, setArtClicked] = useState(false);
  const [isProjectsClicked, setProjectsClicked] = useState(false);
  const [isPartnersClicked, setPartnersClicked] = useState(false);

  const handleCloseMenu = () => {
    setOpen(false);
    setArtClicked(false);
    setProjectsClicked(false);
    setPartnersClicked(false);
  };

  return (
    <div className="fixed z-30 ">
      <div className=" top-[5px] right-0 fixed z-20 pt-2 px-4  bug:hidden">
        <Hamburger
          color="#ffffff"
          toggled={isOpen}
          size="26"
          rounded
          toggle={setOpen}
        />
      </div>
      <motion.div
        initial={"offScreen"}
        whileInView={"onScreen"}
        variants={sidebarAnimation}
        className={isOpen ? "" : "hidden"}
      >
        <div className="py-8 flex-col text-white text-base bg-black w-[100%] fixed top-[72px] left-0 bug:hidden border-t-[1px]  border-white">
          <div className=" text-center space-y-4 pb-8">
            <Link
              to="/marketing-creativity"
              onClick={() => {
                setOpen(false);
              }}
            >
              <div>Marketing Creativity</div>
            </Link>
            <div>
              <Link
                to="/clients"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Clients
              </Link>
            </div>

            {/* PARTNERS */}
            <div
              onClick={() => {
                setPartnersClicked(!isPartnersClicked);
              }}
            >
              <div className="flex justify-center items-center cursor-pointer">
                <p>partners</p>
                <DropDown className="h-3 w-3 ml-2" />
              </div>

              <div
                className={
                  isPartnersClicked
                    ? "flex flex-col text-[12px] leading-[24px] pt-2"
                    : "hidden"
                }
              >
                <Link onClick={handleCloseMenu} to="/partners/vondoom">
                  vondoom
                </Link>
                <Link onClick={handleCloseMenu} to="/partners/vaskange">
                  vaskange
                </Link>
                <Link onClick={handleCloseMenu} to="/partners/fairxyz">
                  fair.xyz
                </Link>
              </div>
            </div>

            {/* OUR ART */}
            <div
              onClick={() => {
                setArtClicked(!isArtClicked);
              }}
            >
              <div className="flex justify-center items-center cursor-pointer">
                <p>our art</p>
                <DropDown className="h-3 w-3 ml-2" />
              </div>

              <div
                className={
                  isArtClicked
                    ? "flex flex-col text-[12px] leading-[24px] pt-2"
                    : "hidden"
                }
              >
                <Link
                  onClick={handleCloseMenu}
                  to="/our-art/process-and-illustrations"
                >
                  process
                </Link>
                <Link onClick={handleCloseMenu} to="/our-art/showroom">
                  showroom
                </Link>
              </div>
            </div>

            {/* OUR PROJECTS */}
            <div
              onClick={() => {
                setProjectsClicked(!isProjectsClicked);
              }}
            >
              <div className="flex justify-center items-center cursor-pointer">
                <p>our projects</p>
                <DropDown className="h-3 w-3 ml-2" />
              </div>

              <div
                className={
                  isProjectsClicked
                    ? "flex flex-col text-[12px] leading-[24px] pt-2"
                    : "hidden"
                }
              >
                <Link onClick={handleCloseMenu} to="/our-projects/tokens">
                  tokens
                </Link>
                <Link onClick={handleCloseMenu} to="/our-projects/ethereum">
                  ethereum
                </Link>
                <Link onClick={handleCloseMenu} to="/our-projects/avalanche">
                  avalanche
                </Link>
              </div>
            </div>

            <button onClick={() => setOpen(false)}>
              <CalendlyBtn
                buttonType="mobileNav"
                label="Book free consultation"
              />
            </button>

            {/* FOLLOW US */}
          </div>
          <div className="text-gray-500 font-spaceGroteskLight text-center text-xs pb-2">
            FOLLOW US
          </div>
          <div className="flex justify-center space-x-2 pb-1">
            <a
              href="https://twitter.com/webuidl_"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setOpen(false);
              }}
            >
              <TwitterIcon className="h-6 w-6" />
            </a>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Sidebar;
