import React from "react";
import ClientsTop from "../../Components/Clients/ClientsTop";
import Header from "../../Components/Header/Header";
import ClientsMiddle from "../../Components/Clients/ClientsMiddle";
import Footer from "../../Components/Footer/Footer";

const Clients = () => {
  return (
    // REMOVE THE FUCKING H-SCREEN
    <div className="text-white ">
      <Header />
      <ClientsTop />
      <ClientsMiddle />
      <Footer />
    </div>
  );
};

export default Clients;
