import React from "react";
import tron from "../../Assets/CompaniesHelped/Tron.webp";
import etoro from "../../Assets/CompaniesHelped/etoro.webp";
import company1 from "../../Assets/CompaniesHelped/1.png";
import company2 from "../../Assets/CompaniesHelped/2.png";
import company3 from "../../Assets/CompaniesHelped/3.png";
import company4 from "../../Assets/CompaniesHelped/4.png";
import company6 from "../../Assets/CompaniesHelped/6.png";
import company7 from "../../Assets/CompaniesHelped/7.png";
import company8 from "../../Assets/CompaniesHelped/8.png";

import seen1 from "../../Assets/CompaniesHelped/PlacesSeen/1.png";
import seen2 from "../../Assets/CompaniesHelped/PlacesSeen/2.png";
import seen3 from "../../Assets/CompaniesHelped/PlacesSeen/3.png";
import seen4 from "../../Assets/CompaniesHelped/PlacesSeen/4.png";

const companies = [
  {
    id: 1,
    img: company1,
  },
  {
    id: 2,
    img: company2,
  },
  {
    id: 3,
    img: company6,
  },
  {
    id: 4,
    img: company4,
  },
  {
    id: 5,
    img: company3,
  },
  {
    id: 6,
    img: company7,
  },
  {
    id: 7,
    img: company8,
  },
];

const placesSeen = [
  {
    id: 1,
    img: seen1,
  },
  {
    id: 2,
    img: seen2,
  },
  {
    id: 3,
    img: seen3,
  },
  {
    id: 4,
    img: seen4,
  },
];

const CompaniesHelped = () => {
  return (
    <div className="flex flex-col items-center font-jersey mt-44 text-2xl">
      <div className="">Some companies we've helped grow</div>

      <div className="flex flex-wrap justify-center items-center space-x-10 gap-6 pt-8 px-4 tab:px-10">
        {companies.map((company) => (
          <div key={company.id} className=" ">
            <img
              src={company.img}
              alt="company"
              className={
                company.id === 2
                  ? "h-[40px]"
                  : company.id === 5
                  ? "h-[50px]"
                  : "h-[80px]"
              }
            />
          </div>
        ))}
      </div>

      <div className="mt-24">Places you may have seen us</div>

      <div className="flex flex-wrap justify-center items-center space-x-10 gap-6 pt-8 px-4 tab:px-10">
        {placesSeen.map((company) => (
          <div key={company.id} className=" ">
            <img
              src={company.img}
              alt="places-seen"
              className={
                company.id === 1
                  ? "h-[40px]"
                  : company.id === 2
                  ? "h-[100px]"
                  : company.id === 3
                  ? "h-[60px]"
                  : "h-[70px]"
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompaniesHelped;
