import React from "react";
import Header from "../../Components/Header/Header";
import AvaxTop from "../../Components/Avax/AvaxTop";
import Footer from "../../Components/Footer/Footer";
import AvaxMiddle from "../../Components/Avax/AvaxMiddle";

const Avax = () => {
  return (
    <div className="text-white">
      <Header />
      <AvaxTop />
      <AvaxMiddle />
      <Footer />
    </div>
  );
};

export default Avax;
