import React, { useState } from "react";
import { ReactComponent as TwitterIcon } from "../../Assets/Twitter.svg";
import { ReactComponent as DropDown } from "../../Assets/DropDown.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Sidebar from "./Sidebar";
// import LandingMarquee from "../Marquees/LandingMarquee";
import logo from "../../Assets/logo.gif";
import { PopupWidget, PopupButton } from "react-calendly";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";

const menuAni = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.15,
    },
  },
};

const HomeHeader = () => {
  const [isShown, setShown] = useState(0);

  const ProjectDropMenu = () => {
    return (
      <motion.div
        onMouseEnter={() => setShown(1)}
        onMouseLeave={() => setShown(0)}
        variants={menuAni}
        initial="offScreen"
        animate={isShown === 1 ? "onScreen" : "offScreen"}
        className=""
      >
        <div
          className={
            isShown === 1
              ? "h-[100px] w-full bg-black absolute border-b-[1px] flex justify-center items-center text-lg text-white space-x-20 border-white"
              : "hidden"
          }
        >
          <Link
            to="/our-projects/tokens"
            className="hover:text-gray-300 cursor-pointer"
            onClick={() => setShown(0)}
          >
            Tokens
          </Link>
          <Link
            to="/our-projects/ethereum"
            className="hover:text-gray-300 cursor-pointer"
            onClick={() => setShown(0)}
          >
            ETHEREUM
          </Link>
          <Link
            to="/our-projects/avalanche"
            className="hover:text-gray-300 cursor-pointer"
            onClick={() => setShown(0)}
          >
            AVALANCHE
          </Link>
        </div>
      </motion.div>
    );
  };

  const ArtDropMenu = () => {
    return (
      <motion.div
        onMouseEnter={() => setShown(2)}
        onMouseLeave={() => setShown(0)}
        variants={menuAni}
        initial="offScreen"
        animate={isShown === 2 ? "onScreen" : "offScreen"}
        className=""
      >
        <div
          className={
            isShown === 2
              ? "h-[100px] w-full bg-black absolute border-b-[1px] flex justify-center items-center text-lg text-white space-x-20 border-white"
              : "hidden"
          }
        >
          <Link
            to="/our-art/process-and-illustrations"
            className="hover:text-gray-300 cursor-pointer flex space-x-2"
            onClick={() => setShown(0)}
          >
            <p>PROCESS</p>
            <p className="font-sans font-bold">&</p>
            <p>ILLUSTRATIONS</p>
          </Link>
          <Link
            to="/our-art/showroom"
            className="hover:text-gray-300 cursor-pointer"
            onClick={() => setShown(0)}
          >
            SHOWROOM
          </Link>
        </div>
      </motion.div>
    );
  };

  const PartnersDropMenu = () => {
    return (
      <motion.div
        onMouseEnter={() => setShown(3)}
        onMouseLeave={() => setShown(0)}
        variants={menuAni}
        initial="offScreen"
        animate={isShown === 3 ? "onScreen" : "offScreen"}
      >
        <div
          className={
            isShown === 3
              ? "h-[100px] w-full bg-black absolute border-b-[1px] flex justify-center items-center text-lg text-white space-x-20 border-white"
              : "hidden"
          }
        >
          <Link
            to="/partners/vondoom"
            className="hover:text-gray-300 cursor-pointer"
            onClick={() => setShown(0)}
          >
            VONDOOM
          </Link>
          <Link
            to="/partners/vaskange"
            className="hover:text-gray-300 cursor-pointer"
            onClick={() => setShown(0)}
          >
            VASKANGE
          </Link>
          <Link
            to="/partners/fairxyz"
            className="hover:text-gray-300 cursor-pointer"
            onClick={() => setShown(0)}
          >
            FAIR.XYZ
          </Link>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="fixed z-50 top-0 text-white font-jersey w-full   bg-black">
      <div className="flex justify-between items-center px-6  border-b-[1px] border-white ">
        <Link to="/" className="text-4xl py-4">
          <img src={logo} alt="logo" className="h-12 w-12" />
        </Link>
        <div className="bug:block hidden">
          <div className="flex items-center text-[14px] bug:text-lg space-x-6 bug:space-x-8">
            <Link
              to="/marketing-creativity"
              className="hover:text-gray-300 cursor-pointer flex items-center py-8"
            >
              <p className="pr-2">Marketing Creativity</p>
              {/* <DropDown className="h-3 w-3" /> */}
            </Link>

            <div
              onMouseEnter={() => setShown(1)}
              onMouseLeave={() => setShown(0)}
              className="hover:text-gray-300 cursor-pointer flex items-center py-8"
            >
              <p className="pr-2">Our Projects</p>
              <DropDown className="h-3 w-3" />
            </div>
            <div
              onMouseEnter={() => setShown(2)}
              onMouseLeave={() => setShown(0)}
              className="hover:text-gray-300 cursor-pointer flex items-center py-8"
            >
              <p className="pr-2">Our Art</p>
              <DropDown className="h-3 w-3" />
            </div>

            <div
              onMouseEnter={() => setShown(3)}
              onMouseLeave={() => setShown(0)}
              className="hover:text-gray-300 cursor-pointer flex items-center py-8"
            >
              <p className="pr-2">Partners</p>
              <DropDown className="h-3 w-3" />
            </div>

            <Link
              to="/clients"
              className="hover:text-gray-300 cursor-pointer py-8"
            >
              Clients
            </Link>
          </div>
        </div>
        <div className="flex items-center gap-8">
          <a
            href="https://twitter.com/webuidl_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon className="h-8 w-8 bug:block hidden" />
          </a>

          <CalendlyBtn buttonType="header" label="book free consultation" />
        </div>

        <Sidebar />
      </div>
      <ProjectDropMenu />
      <ArtDropMenu />
      <PartnersDropMenu />
      {/* <div className={isShown === 0 ? "" : "hidden"}>
        <LandingMarquee />
      </div> */}
    </div>
  );
};

export default HomeHeader;
