import React from "react";
import { motion } from "framer-motion";

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const ClientsTop = () => {
  return (
    <div className="bg-black  text-white pb-44 font-jersey">
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
      >
        <div className="text-center pt-28 text-7xl ml:text-8xl tab:text-9xl bug:text-[225px] font-jersey leading-none pb-32">
          <p>our</p>
          <p>clients</p>
        </div>
        <div className="text-center font-jersey text-xl tab:text-2xl text-gray-500 pb-4">
          some of the people
        </div>
        {/* <div className="flex justify-center items-center mx-auto space-x-4 tab:space-x-14 pb-28">
              <img
                src={Alto}
                alt="MarketplaceLogo"
                className="h-16 w-16 tab:h-24 tab:w-24"
              />
            </div> */}
        {/* <div className="text-center">COMING SOON TO</div> */}
        <div className="text-center px-4 tab:px-20 space-y-8">
          <p className="font-jersey text-3xl ml:text-4xl tab:text-5xl  ">
            we have worked with
          </p>
          <p className="text-base tab:text-3xl font-spaceGroteskReg text-gray-400 px-2 max-w-6xl mx-auto">
            Since 2019, we have been working with multiple partners to scale
            their marketing, build accurate research on the market, and actively
            take part in the marketing research Here are some of our phare
            case-study clients :
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default ClientsTop;
