import React from "react";
import { PopupButton } from "react-calendly";

const BUTTON_TYPE_CLASSES = {
  header: "px-3 py-1.5 text-black bg-[#FFC825] text-lg bug:block hidden",
  mobileNav: "px-3 py-1.5 w-[180px] text-black bg-[#FFC825] text-base mx-auto",
  landing:
    "px-3 py-2.5 text-black bg-[#FFC825] text-xl  w-[250px] mt-8 ml-6 flex justify-center items-center",
  stats:
    "px-3 py-2.5 text-black bg-[#FFC825] text-xl  w-[250px] mt-8 tab:ml-6 mx-auto flex justify-center items-center",
  actionCall:
    "px-3 py-3.5 text-black bg-[#FFC825] text-xl  w-[305px] mt-8 ml-6 flex justify-center items-center font-jersey ",
  influencerTable:
    "w-[33%] tab:w-[25%] flex justify-end text-[#FFC825] font-normal",
};

export const CalendlyBtn = ({ buttonType, label }) => {
  return (
    <div className={BUTTON_TYPE_CLASSES[buttonType]}>
      <PopupButton
        url="https://calendly.com/ayman-eth/intro-call"
        rootElement={document.getElementById("root")}
        text={label}
      />
    </div>
  );
};
