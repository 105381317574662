import React from "react";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import anime1 from "../../Assets/Art/anime1.jpg";
import anime2 from "../../Assets/Art/anime2.png";
import anime3 from "../../Assets/Art/anime3.jpg";
import anime4 from "../../Assets/Art/anime4.jpg";
import anime5 from "../../Assets/Art/anime5.jpg";
import anime6 from "../../Assets/Art/anime6.jpg";
import anime7 from "../../Assets/Art/anime7.jpg";
import anime8 from "../../Assets/Art/anime8.jpg";
import anime9 from "../../Assets/Art/anime9.jpg";
import anime10 from "../../Assets/Art/anime10.jpg";
import anime11 from "../../Assets/Art/anime11.gif";
import anime12 from "../../Assets/Art/anime12.jpg";

const animeArt = [
  { id: 1, image: anime1 },
  { id: 2, image: anime2 },
  { id: 3, image: anime3 },
  { id: 4, image: anime4 },
  { id: 5, image: anime5 },
  { id: 6, image: anime6 },
  { id: 7, image: anime7 },
  { id: 8, image: anime8 },
  { id: 9, image: anime9 },
  { id: 10, image: anime10 },
  { id: 11, image: anime11 },
  { id: 12, image: anime12 },
];
const AnimeMarquee = () => {
  return (
    <div className="flex">
      {animeArt.map((nftAv) => {
        return (
          <div className="px-4">
            <img
              src={nftAv.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
      delay: 0.2,
    },
  },
};

const AnimeArtMarquee = () => {
  return (
    <div>
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
        className="font-jersey text-center text-7xl pb-20"
      >
        anime art
      </motion.div>
      <div className="space-y-10 ">
        <Marquee gradient={false} speed={100}>
          <AnimeMarquee />
        </Marquee>
        <Marquee gradient={false} speed={100} direction="right">
          <AnimeMarquee />
        </Marquee>
      </div>
    </div>
  );
};

export default AnimeArtMarquee;
