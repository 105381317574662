import React from "react";
import MarketingTop from "../../Components/Marketing/Top";
import MarketingMiddle from "../../Components/Marketing/Middle";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const MarketingCreativity = () => {
  return (
    <div className="">
      <Header />
      <MarketingTop />
      <MarketingMiddle />
      <Footer />
    </div>
  );
};

export default MarketingCreativity;
