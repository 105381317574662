import React from "react";
import { motion } from "framer-motion";
import logo from "../../Assets/pepelandlogo.gif";
import logo2 from "../../Assets/PepelandLogoTransparent.png";

const fadeAnimation = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const EthTop = () => {
  return (
    <div className="bg-black pb-64 text-white ">
      <motion.div
        variants={fadeAnimation}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true }}
      >
        <div className="text-center pt-28 text-7xl ml:text-8xl tab:text-9xl bug:text-[225px] font-jersey leading-none pb-32">
          <p>eth</p>
          <p>projects</p>
        </div>
        <div
          className="text-center font-jersey  pb-16
        text-3xl ml:text-4xl tab:text-6xl flex flex-col justify-center items-center "
        >
          <div className="flex items-center gap-4">
            <p>pepeland</p>
            <img src={logo} alt="pepeland-logo" className="w-12 h-12" />
          </div>
          <a
            href="https://pepeland.fun/"
            target="_blank"
            rel="noopener noreferrer"
            className=" flex items-center font-jersey text-base pt-4 gap-2"
          >
            <p>Click here to access website</p>
            <img src={logo2} alt="pepeland-logo" className="w-4 h-4" />
          </a>
        </div>

        <div className="text-center font-jersey px-4 tab:px-20 space-y-8">
          <p className=" text-xl ml:text-2xl tab:text-4xl  ">
            5 555 SPECIES LEADING THE WAY TO PEPELAND
          </p>
          <p className="text-base tab:text-2xl text-gray-400 px-2 max-w-7xl mx-auto">
            The Pepeland project was a spinoff degen version of Memeland, a
            project made by 9gag, one of the biggest meme platforms.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default EthTop;
