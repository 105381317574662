import React from "react";
import Marquee from "react-fast-marquee";
import webx1 from "../../Assets/webx1.jpg";
import webx2 from "../../Assets/webx2.jpg";
import webx3 from "../../Assets/webx3.jpg";
import webx4 from "../../Assets/webx4.jpg";

const webx = [
  { id: 1, image: webx1 },
  { id: 2, image: webx2 },
  { id: 3, image: webx3 },
  { id: 4, image: webx4 },
];

const WebxSlide = () => {
  return (
    <div className="flex">
      {webx.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const WebxMarquee = () => {
  return (
    <div>
      <Marquee gradient={false} speed={100}>
        <WebxSlide />
      </Marquee>
    </div>
  );
};

export default WebxMarquee;
