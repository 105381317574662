import React from "react";
import Marquee from "react-fast-marquee";
import avax1 from "../../Assets/Avax/avax1.png";
import avax2 from "../../Assets/Avax/avax2.jpeg";
import avax3 from "../../Assets/Avax/avax3.jpeg";
import avax4 from "../../Assets/Avax/avax4.jpeg";

const avaxvol = [
  { id: 1, image: avax1 },
  { id: 2, image: avax2 },
  { id: 3, image: avax3 },
  { id: 4, image: avax4 },
];

const AvaxSlide = () => {
  return (
    <div className="flex">
      {avaxvol.map((img) => {
        return (
          <div className="px-4">
            <img
              src={img.image}
              alt="avatar"
              className="object-cover rounded-xl h-64 w-64 tab:h-80 tab:w-80 md:rounded-3xl border-4 border-black  "
            />
          </div>
        );
      })}
    </div>
  );
};

const AvaxVolMarquee = () => {
  return (
    <div>
      <Marquee gradient={false} speed={100}>
        <AvaxSlide />
      </Marquee>
    </div>
  );
};

export default AvaxVolMarquee;
