import React from "react";
import { NumberAnimationNoDecimals } from "../NumberAnimation/NumberAnimation";
import { CalendlyBtn } from "../CalendlyBtn/CalendlyBtn";
import { motion } from "framer-motion";

const stats = [
  { id: 1, number: 73, label: "happy clients" },
  { id: 2, number: 5, label: "Years of Crypto Marketing Experience" },
  { id: 3, number: 98, label: "Campaigns Delivered" },
  {
    id: 4,
    number: 4,
    label: "Top Market Cap Crypto Companies Represented",
  },
];

const fadeAnimation = (delay) => {
  return {
    offScreen: {
      y: 50,
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 100,
      transition: {
        delay: delay,
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };
};

const Statistics = () => {
  return (
    <div className=" font-jersey flex flex-col tab:flex-row justify-center items-center tab:gap-4 bug:gap-44 text-xl px-4 tab:px-10">
      <div className="pb-10 tab:pb-0 text-center tab:text-start">
        <div className="space-y-6">
          <p className="text-gray-300">A Top Web3 & Crypto Marketing Agency</p>
          <p className="text-4xl">We help Web3 companies go viral</p>
          <p>Promote your brand with the biggest names in crypto</p>
        </div>
        <CalendlyBtn buttonType="stats" label="book free consultation" />
      </div>

      {/* <div className="space-y-4">
        <div className="flex gap-4">
          <div className="w-[250px] h-[250px] border-[0.5px] border-gray-700 rounded-md flex justify-center items-center text-7xl">
            <NumberAnimationNoDecimals n={100} />
          </div>
          <div className="w-[250px] h-[250px] border-[0.5px] border-gray-700 rounded-md flex justify-center items-center text-7xl">
            <NumberAnimationNoDecimals n={63} />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-[250px] h-[250px] border-[0.5px] border-gray-700 rounded-md flex justify-center items-center text-7xl">
            <NumberAnimationNoDecimals n={920} />
          </div>
          <div className="w-[250px] h-[250px] border-[0.5px] border-gray-700 rounded-md flex justify-center items-center text-7xl">
            <NumberAnimationNoDecimals n={82} />
          </div>
        </div>
      </div> */}

      <div className="space-y-4">
        {stats.map((stat) => (
          <motion.div
            variants={fadeAnimation(stat.id * 0.2)}
            initial="offScreen"
            whileInView="onScreen"
            viewport={{ once: true, amount: 0.3 }}
            key={stat.id}
            className="bg-[#171717] text-4xl w-[300px] tab:w-[400px] py-2 px-4"
          >
            <p className="text-base tab:text-lg pb-3">{stat.label}</p>
            <div className="flex items-center gap-3">
              <NumberAnimationNoDecimals n={stat.number} />
              <span className="text-white text-4xl font-mono">+</span>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Statistics;
